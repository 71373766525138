import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import { privacyPolicy, interpretationAndDefinitions, Definitions, personalData, usageData, informationFrom, trackingTechnologies,
         useOfYourPersonalData, personalizedData, disclosurePersonalData, securityToAnalyticsData, googleAnalyticsData, emailMarketingPaymentsData,
         stripeData, behavioralRemarkingData, googleAdsAndData, legalBasisAndGDPRData, exercisingOfYourGDPRAndCategoryData, CategoryKAndPurposes,
         SaleAnd16YearsOfAgeData, yourRightsUnderTheCCPAData, ExercisingYourCCPADataProtectionRightsData, DoNotSellMyPersonalInformationData, WebsiteAndMobileDevicesData,
         DoNotTrackData, ChildrenPrivacyData, NecessaryEssentialCookiesData, CookiesAndTrackingCookiesData, UseOfYourPersonalData, RetentionAndTransferData,
         DisclosureOfYourPersonalData, SecurityAndAnalyticsData, GoogleAnalyticsData, EmailMarketingAndStripeData, BehavioralRemarketingData, GoogleAdWordsAndFacebookData,
         LegalBasisAndGDPRData, ExercisingAndCategoryJData, CategoryKAndPersonalInformationData, SaleOfPersonalInformationData, YourRightsData, ExercisingData,
         DoNotSellData, WebsiteAndMobileData, DoNotTrackAndChildrenData } from "../../utils/privacy-policy/Data";
import Company from '../../common/components/company/Company';
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction';


export default function PrivacyPolicyPage() {
  return (
    <>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          {privacyPolicy.map((policy, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-title mb-3 mb-lg-5'>{policy.title}</h3>
              {policy.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {interpretationAndDefinitions.map((interpretation, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-privacy-title mb-3 mb-lg-5'>{interpretation.title}</h3>
              <h4 className='custom-dark-color font-poppins-bold default-title'>{interpretation.subTitle}</h4>
              <p className='default-sm-description'>{interpretation.description}</p>
            </div>
          ))}
          {Definitions.map((definition, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-privacy-title mb-3 mb-lg-5'>{definition.title}</h3>
              {definition.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description'>{contentItem.paragraph}</p>
                  <ul>
                    {contentItem.descriptions.map((description, index) => (
                      <li className='default-sm-description mb-2' key={index}><span className='custom-dark-color font-poppins-semi-bold'>{description.point} </span>{description.description}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {personalData.map((pData, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h2 className='font-poppins-bold default-privacy-title mb-3 mb-lg-5'>Collecting and Using Your Personal Data</h2>
                <h3 className='font-poppins-bold default-extra-special-xl-title mb-3 mb-lg-5'>Types of Data Collected</h3>
                <h3 className='font-poppins-bold default-md-title'>{pData.title}</h3>
              </div>
              {pData.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description'>{contentItem.paragraph}</p>
                  <ul>
                    {contentItem.descriptions.map((description, index) => (
                      <li className='default-sm-description mb-2' key={index}><span className=''>{description.point} </span>{description.description}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {usageData.map((uData, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h3 className='font-poppins-bold default-md-title'>{uData.title}</h3>
              </div>
              {uData.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {informationFrom.map((information, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h3 className='font-poppins-bold default-md-title'>{information.title}</h3>
              </div>
              <p className='default-sm-description'>{information.paragraph}</p>
              <ul>
                {information.pointItems.map((pointItem, index) => (
                    <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {information.descriptions.map((description, index) => (
                <p className='default-sm-description mb-2' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {trackingTechnologies.map((trackingTechnology, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h3 className='font-poppins-bold default-md-title'>{trackingTechnology.title}</h3>
              </div>
              <p className='default-sm-description'>{trackingTechnology.paragraph}</p>
              <ul>
                {trackingTechnology.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {trackingTechnology.descriptions.map((description, index) => (
                <p className='default-sm-description mb-2' key={index}>{description.description}</p>
              ))}
              {trackingTechnology.infoItems.map((infoItem, index) => (
                <div key={index}>
                  <h5 className='default-lg-description my-3 custom-dark-color font-poppins-semi-bold' key={index}>{infoItem.title}</h5>
                  <ul>
                    {infoItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                  {infoItem.descriptions.map((description, index) => (
                    <p className='default-sm-description mb-2' key={index}>{description.description}</p>
                  ))}
                </div>
              ))}
            </div>
          ))}
          {useOfYourPersonalData.map((useOfYourPersonal, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title'>{useOfYourPersonal.title}</h3>
              {useOfYourPersonal.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description'>{contentItem.paragraph}</p>
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                        <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {personalizedData.map((personalized, index) => (
            <div className='mb-3' key={index}>
              <h3 className='font-poppins-bold default-title mb-3 mb-lg-3'>{personalized.title}</h3>
              {personalized.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {disclosurePersonalData.map((disclosurePersonal, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title'>{disclosurePersonal.title}</h3>
              {disclosurePersonal.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <h5 className='custom-dark-color font-poppins-semi-bold'>{contentItem.title}</h5>
                  <p className='default-sm-description'>{contentItem.description}</p>
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                        <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {securityToAnalyticsData.map((securityToAnalytic, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title'>{securityToAnalytic.title}</h3>
              <p className='default-sm-description'>{securityToAnalytic.description}</p>
            </div>
          ))}
          {googleAnalyticsData.map((googleAnalytic, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-title'>{googleAnalytic.title}</h3>
              <ul>
                {googleAnalytic.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {emailMarketingPaymentsData.map((emailMarketingPayment, index) => (
            <div className='mb-3' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{emailMarketingPayment.title}</h3>
              {emailMarketingPayment.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {stripeData.map((stripe, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-title'>{stripe.title}</h3>
              <ul>
                <li className='default-sm-description'>{stripe.point}</li>
              </ul>
            </div>
          ))}
          {behavioralRemarkingData.map((behavioralRemarking, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{behavioralRemarking.title}</h3>
              {behavioralRemarking.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {behavioralRemarking.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {behavioralRemarking.description2.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {googleAdsAndData.map((googleAds, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-title'>{googleAds.title}</h3>
              <ul>
                {googleAds.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          <h3 className='font-poppins-bold default-title mb-lg-4'>GDPR Privacy</h3>
          {legalBasisAndGDPRData.map((legalBasisAndGDPR, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title'>{legalBasisAndGDPR.title}</h3>
              {legalBasisAndGDPR.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {legalBasisAndGDPR.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2'>{pointItem.point}</li>
                ))}
              </ul>
              <p className='default-sm-description' key={index}>{legalBasisAndGDPR.paragraph}</p>
            </div>
          ))}
          {exercisingOfYourGDPRAndCategoryData.map((exercisingOfYourGDPRAndCategory, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{exercisingOfYourGDPRAndCategory.title}</h3>
              {exercisingOfYourGDPRAndCategory.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {CategoryKAndPurposes.map((CategoryKAndPurpose, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{CategoryKAndPurpose.title}</h3>
              {CategoryKAndPurpose.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {CategoryKAndPurpose.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {CategoryKAndPurpose.moreDescriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {SaleAnd16YearsOfAgeData.map((SaleAnd16YearsOfAge, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{SaleAnd16YearsOfAge.title}</h3>
              {SaleAnd16YearsOfAge.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {SaleAnd16YearsOfAge.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {yourRightsUnderTheCCPAData.map((yourRightsUnderTheCCPA, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{yourRightsUnderTheCCPA.title}</h3>
              {yourRightsUnderTheCCPA.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description' key={index}>{contentItem.description}</p>
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {ExercisingYourCCPADataProtectionRightsData.map((ExercisingYourCCPADataProtectionRight, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{ExercisingYourCCPADataProtectionRight.title}</h3>
              {ExercisingYourCCPADataProtectionRight.contentItems.map((contentItem, index) => (
                <div key={index}>
                  {contentItem.descriptions.map((description, index) => (
                    <p className='default-sm-description' key={index}>{description.description}</p>
                  ))}
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                  {contentItem.moreDescriptions.map((description, index) => (
                    <p className='default-sm-description' key={index}>{description.description}</p>
                  ))}
                </div>
              ))}
            </div>
          ))}
          {DoNotSellMyPersonalInformationData.map((DoNotSellMyPersonalInformation, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{DoNotSellMyPersonalInformation.title}</h3>
              {DoNotSellMyPersonalInformation.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {WebsiteAndMobileDevicesData.map((WebsiteAndMobileDevice, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{WebsiteAndMobileDevice.title}</h3>
              {WebsiteAndMobileDevice.contentItems.map((contentItem, index) => (
                <div key={index}>
                  {contentItem.descriptions.map((description, index) => (
                    <p className='default-sm-description' key={index}>{description.description}</p>
                  ))}
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                  {contentItem.moreDescriptions.map((description, index) => (
                    <p className='default-sm-description' key={index}>{description.description}</p>
                  ))}
                </div>
              ))}
            </div>
          ))}
          {DoNotTrackData.map((DoNotTrack, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{DoNotTrack.title}</h3>
              {DoNotTrack.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {ChildrenPrivacyData.map((ChildrenPrivacy, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{ChildrenPrivacy.title}</h3>
              {ChildrenPrivacy.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {ChildrenPrivacy.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {ChildrenPrivacy.moreDescriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {NecessaryEssentialCookiesData.map((NecessaryEssentialCookie, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold custom-dark-color default-special-sm-title'>{NecessaryEssentialCookie.title}</h3>
              <ul>
                {NecessaryEssentialCookie.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2'>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {CookiesAndTrackingCookiesData.map((CookiesAndTrackingCookie, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold custom-dark-color default-special-sm-title'>{CookiesAndTrackingCookie.title}</h3>
              {CookiesAndTrackingCookie.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {UseOfYourPersonalData.map((UseOfYourPersonal, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{UseOfYourPersonal.title}</h3>
              {UseOfYourPersonal.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description' key={index}>{contentItem.description}</p>
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {RetentionAndTransferData.map((RetentionAndTransfer, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-special-sm-title'>{RetentionAndTransfer.title}</h3>
              {RetentionAndTransfer.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {DisclosureOfYourPersonalData.map((DisclosureOfYourPersonal, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-special-sm-title mb-3'>{DisclosureOfYourPersonal.title}</h3>
              {DisclosureOfYourPersonal.sectionInfo.map((sInfo, index) => (
                <div key={index}>
                  <h6 className='custom-dark-color font-poppins-bold default-sm-description'>{sInfo.subTitle}</h6>
                  <p className='default-sm-description'>{sInfo.description}</p>
                </div>
              ))}
              <ul>
                {DisclosureOfYourPersonal.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2'>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {SecurityAndAnalyticsData.map((SecurityAndAnalytic, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-special-sm-title'>{SecurityAndAnalytic.title}</h3>
              <p className='default-sm-description' key={index}>{SecurityAndAnalytic.description}</p>
            </div>
          ))}
          {GoogleAnalyticsData.map((GoogleAnalytic, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-special-sm-title'>{GoogleAnalytic.title}</h3>
              <ul>
                {GoogleAnalytic.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2'>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {EmailMarketingAndStripeData.map((EmailMarketingAndStripe, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{EmailMarketingAndStripe.title}</h3>
              {EmailMarketingAndStripe.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {BehavioralRemarketingData.map((BehavioralRemarketing, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{BehavioralRemarketing.title}</h3>
              <div>
                {BehavioralRemarketing.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
                <ul>
                  {BehavioralRemarketing.pointItems.map((pointItem, index) => (
                    <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                  ))}
                </ul>
                {BehavioralRemarketing.moreDescriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
              </div>
            </div>
          ))}
          {GoogleAdWordsAndFacebookData.map((GoogleAdWordsAndFacebook, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-special-sm-title'>{GoogleAdWordsAndFacebook.title}</h3>
              <ul>
                {GoogleAdWordsAndFacebook.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2'>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {LegalBasisAndGDPRData.map((LegalBasisAndGDPR, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{LegalBasisAndGDPR.title}</h3>
              <div>
                {LegalBasisAndGDPR.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
                <ul>
                  {LegalBasisAndGDPR.pointItems.map((pointItem, index) => (
                    <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                  ))}
                </ul>
                {LegalBasisAndGDPR.moreDescriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
              </div>
            </div>
          ))}
          {ExercisingAndCategoryJData.map((ExercisingAndCategoryJ, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{ExercisingAndCategoryJ.title}</h3>
              {ExercisingAndCategoryJ.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {CategoryKAndPersonalInformationData.map((CategoryKAndPersonalInformation, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{CategoryKAndPersonalInformation.title}</h3>
              <div>
                {CategoryKAndPersonalInformation.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
                <ul>
                  {CategoryKAndPersonalInformation.pointItems.map((pointItem, index) => (
                    <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                  ))}
                </ul>
                {CategoryKAndPersonalInformation.moreDescriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
              </div>
            </div>
          ))}
          {SaleOfPersonalInformationData.map((SaleOfPersonalInformation, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{SaleOfPersonalInformation.title}</h3>
              {SaleOfPersonalInformation.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {YourRightsData.map((YourRight, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-privacy-title mb-3 mb-lg-5'>{YourRight.title}</h3>
              {YourRight.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description'>{contentItem.description}</p>
                  <ul>
                    {contentItem.pointItems.map((pointItem, index) => (
                      <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {ExercisingData.map((Exercising, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{Exercising.title}</h3>
              <div>
                {Exercising.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
                {Exercising.contentItems.map((contentItem, index) => (
                  <div key={index}>
                    <p className='default-sm-description'>{contentItem.description}</p>
                    <ul>
                      {contentItem.pointItems.map((pointItem, index) => (
                        <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                      ))}
                    </ul>
                  </div>
                ))}
                {Exercising.moreDescriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
                ))}
              </div>
            </div>
          ))}
          {DoNotSellData.map((DoNotSell, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{DoNotSell.title}</h3>
              {DoNotSell.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {WebsiteAndMobileData.map((WebsiteAndMobile, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{WebsiteAndMobile.title}</h3>
              <p className='default-sm-description'>{WebsiteAndMobile.description}</p>
              <ul>
                {WebsiteAndMobile.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              <p className='default-sm-description'>{WebsiteAndMobile.moreDescription}</p>
            </div>
          ))}
          {DoNotTrackAndChildrenData.map((DoNotTrackAndChildren, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{DoNotTrackAndChildren.title}</h3>
              {DoNotTrackAndChildren.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
        </div>
        <div className="col-12 col-lg-4">
          <LetsGrowYourAudienceForm />
        </div>
        <div className="col-12 col-lg-8">
          <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 4 }} />
          <WeGuaranteeSatisfaction smallScreen={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
