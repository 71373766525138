import Lead from '../../assets/images/outbound/image 49.jpg';
import Content from '../../assets/images/outbound/image 50.jpg';
import Customer from '../../assets/images/outbound/image 51.jpg';
import Surveys from '../../assets/images/outbound/image 52.jpg';
import RedirectArrow from '../../assets/images/svg/redirect-arrow.svg';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const outboundServices = [
  {
    title: 'Lead Generation',
    description: 'Generating targeted leads and unlocking opportunities for your brand. Our goal is simple – Integrate with our client’s sales and marketing effort to become a new profit center. We do everything in our power to demonstrate the highest possible ROI.',
    url: Lead,
    icon: RedirectArrow,
    Rectangle: Rectangle
  },
  {
    title: 'Content Marketing Distribution',
    description: 'Telemarketing allows a company to distribute content to a segmented market, based on a prospect’s or client’s specific business needs, as well as follow up with their target audience to determine whether the content is being received.',
    url: Content,
    icon: RedirectArrow,
    Rectangle: Rectangle
  },
  {
    title: 'Customer Development and Winback',
    description: 'Despite new and updated methods of connecting to customers, the telemarketing experience brings the best results in customer retention. It is less expensive to retain a customer than to acquire a new one, so retaining customer is important for your bottom line.',
    url: Customer,
    icon: RedirectArrow,
    Rectangle: Rectangle
  },
  {
    title: 'Surveys & Market Research',
    description: 'The Contact Center  offers market research that reaches out directly to your audience to understand their needs, opinions and wants through a personal (human to human) survey or questionnaire. We select the best team to conduct the survey/research based on your needs.',
    url: Surveys,
    icon: RedirectArrow,
    Rectangle: Rectangle
  }
]