import Results from '../../assets/images/svg/results.svg';
import BoostSales from '../../assets/images/svg/boost-sales.svg';
import Settings from '../../assets/images/svg/settings.svg';
import Sales from '../../assets/images/svg/sales.svg';

import SalesStrategy from '../../assets/images/outbound-lead-generation/image 43.png';
import WeAreThere from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

import BANT from '../../assets/images/outbound-lead-generation/Group_1000000897.png';
import SQL from '../../assets/images/outbound-lead-generation/Group 1000000898.svg';
import MQL from '../../assets/images/outbound-lead-generation/Group 1000000899.svg';



export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'MILLIONS OF QUALIFIED ACTIONABLE LEADS SINCE 1991'
      },
    ],
    title: 'Generating targeted leads and unlocking opportunities for your brand.',
    description: 'Working with The Contact Center  professionals with vast lead generation experience also makes sense.Our goal is simple – Integrate with our client’s sales and marketing effort to become a new profit center. We do everything to deliver the highest possible ROI.',
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'QUALITY LEADS',
        icon: Results,
      },
      {
        link: '/outbound-marketing',
        data: '20% Sales Boost',
        icon: BoostSales,
      },
      {
        link: '/outbound-marketing',
        data: '50% Marketing Savings',
        icon: Settings,
      },
      {
        link: '/outbound-marketing',
        data: '30% Sales Enhancement',
        icon: Sales,
      },
    ]
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Using Telemarketing As an integral part of your sales Strategy.',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'There are few things more important to B2B organizations than their leads. There is rarely such a thing as an impulse-buy when it comes to B2B transactions, which is why it’s important to have a strong list of quality leads filling your sales funnel. Many marketers continue to wonder how to generate leads that result in more business. One of the best strategies involves utilizing the power of telemarketing.'
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Lead generation must be designed a process, not an event. The Contact Center  not only generates preliminary B2B leads; we also offer lead nurturing processes to secure your best return on investment.'
      },
    ],
    image: SalesStrategy,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'We Are There When You Can’t Be, or Supplement Your Agents During Peak Times.',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'It doesn’t matter whether you choose The Contact Center  after hours, on weekends or holidays, or in conjunction with your staff supplementing overflow; we will pick up right where you left off. Our call center technology can integrate with your in-house system to submit support tickets, web-based scheduling, appointment-setting, record orders, address problems, answer inquiries, and so much more.'
      },
    ],
    image: WeAreThere,
    imageRectangle:Rectangle,
    changeDirection: true
  },
]

export const ActionableLeads = [
  {
    title: 'BANT',
    description: 'We secure talking to the right person, who answers all qualification questions (budget, authority, needs, and timeline). This person is ready for direct sales follow-up and should be made a priority to engage with one on one.',
    url: BANT,
    offSet: ''
  },
  {
    title: 'SQL',
    description: '(Sales Qualified Lead) – We secure talking to the right person and obtain the answers to the qualification questions. This person is ready for direct sales follow-up.',
    url: SQL,
    offSet: 'offset-lg-4'
  },
  {
    title: 'MQL',
    description: '(Marketing Qualified Lead) – We secure talking to the right person who answers the qualification questions. This person is primarily a contact that is sales-ready but is not yet ready for direct, personal attention from sales.',
    url: MQL,
    offSet: ''
  }
]

export const pricingTable = [
  {
    title: 'Entry Level Customer Service Representative',
    planItems:[
      {
        name: 'General hiring costs',
        price:'$6,000'
      },
      {
        name: 'Office space',
        price:'$15,000'
      },
      {
        name: 'Software and hardware expenses',
        price:'$3,000'
      },
      {
        name: 'Customer service management',
        price:'$20,000'
      },
      {
        name: 'Employee salaries (with benefits)',
        price:'$65,000'
      }
    ],
    totalAmount: '$109,000.00/year',
    type: 'Total In-House Annual Investment'
  },
  {
    title: 'Mid-Range Customer Service Representative',
    planItems:[
      {
        name: 'General hiring costs',
        price:'$12,000'
      },
      {
        name: 'Office space',
        price:'$25,000'
      },
      {
        name: 'Software and hardware expenses',
        price:'$3,000'
      },
      {
        name: 'Customer service management',
        price:'$20,000'
      },
      {
        name: 'Employee salaries (with benefits)',
        price:'$95,000'
      }
    ],
    totalAmount: '$155,000.00/year',
    type: 'Total In-House Annual Investment'
  },
  {
    title: 'High-End Customer Service Representative',
    planItems:[
      {
        name: 'General hiring costs',
        price:'$20,000'
      },
      {
        name: 'Office space',
        price:'$45,000'
      },
      {
        name: 'Software and hardware expenses',
        price:'$5,000'
      },
      {
        name: 'Customer service management',
        price:'$40,000'
      },
      {
        name: 'Employee salaries (with benefits)',
        price:'$140,000'
      }
    ],
    totalAmount: '$250,000.00/year',
    type: 'Total In-House Annual Investment'
  }
]