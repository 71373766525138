import Combining from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Trained from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE AT YOUR FRANCHISE'
      },
    ],
    title: 'Franchise Call Center Services',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Your Business to Run Seamlessly',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "As an owner of a franchise, you have a lot of communicating to do. From staying up-to-date with the corporate office to informing your employees and supervisors, plus engaging with customers, you may feel as if you never get a break.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "TCC’s inbound contact center services exist to give you a way to ensure your franchise runs smoothly and that you get that much-needed night off. As a completely U.S.-based contact center, we offer a consistent experience as an extension of your in-house team.",
      }
    ],
    image: Combining,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Services That Save You Time and Resources',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'When you need to schedule appointments with contractors or want to conduct a follow-up with employees to ensure that every shift is covered, The Contact Center is your center for communications. As a true multichannel contact center, we offer a range of communication formats, from email and text to phone calls and messaging. Your employees, customers and even the corporate office contact you in the format most convenient to them.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The Contact Center provides the professional image you want for your company, and your callers will never know that they are not talking to a representative sitting in your office. You can stop worrying about the calls you miss or those that are forced to go to voicemail. You’ll never miss an important call again.'
      },
    ],
    image: Trained,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Enjoy Peace of Mind',
    subTitle: 'As a franchise owner, you gain peace of mind knowing that each caller is receiving the high quality of service you envision for your business.',
    descriptions: [
      {
        description: 'Now you can work, uninterrupted, as trained agents handle your calls professionally and even help you grow your business.'
      },
      {
        description: 'The Contact Center also offers reporting to allow you to identify trends in your customer calls and make better-informed business decisions'
      },
      {
        description: 'Owning a franchise comes with a lot of responsibility, but it can also be a fun way to grow in a business venture. Shift the balance to more fun and less responsibility when you outsource your inbound contact center to TCC. Contact us today to learn more.'
      }
    ]
  }
]
