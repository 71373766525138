import Streamline from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import WeMeet from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING YOUR CAMPAIGN CALLING CENTER'
      },
    ],
    title: 'Political Call Center Services',
    description: 'The Contact Center  is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Streamline Your Call Center Efforts',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Political campaigns for the upcoming election are in full swing, imminent deadlines and the need for spontaneous collaboration take priority in your busy day. Fortunately, we are here to help you streamline your call center efforts and reach more contacts in less time.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "The Contact Center  is acutely aware of the escalating technological, demographic, regulatory and social challenges faced by live call center programs. Some have given up. We have not. We adapt and design programs to deal with the demands of micro targeting and decreased land line usage.",
      }
    ],
    image: Streamline,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'We meet the unique needs of your specific campaign',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Unlike other call center firms, we have multi-year veterans of Democratic, Republican and independent political campaigns from the Presidential, congressional and local levels on board who know the concerns of candidates, consultants, polling organizations, public policy groups and others involved in the public issues sphere.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'At The Contact Center , we offer full-service call center capabilities for your Voter ID, Robo-call, Town Hall Meetings and of course, Live Operator interactions. All configurations are possible to help you meet the unique needs of your specific campaign. We will function as a direct extension of your in-house team, offering you access to our diverse and skilled political agent base with our call center services.'
      },
    ],
    image: WeMeet,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

