export const connectionIsTheHear = [
  {
    title: 'Be There for Your Customers',
    descriptions: [
      {
        description: 'Your work doesn’t end at 5:00, because your customers use utilities at every hour of the day. Whether it’s an emergency with widespread outages, or a customer simply calling to decipher their invoice, you need to be able to provide quick, convenient information.'
      },
      {
        description: 'What customers don’t want is to be placed in a voicemail inbox or funneled through a robotic system. But you need a cost-efficient way to be available to them around the clock.'
      }
    ]
  }
]

export const jumpOnOpportunity = [
  {
    title: 'Handling Emergencies and Routine Inquiries',
    descriptions: [
      {
        description: 'Managing communications with your customers during a high call volume associated with inclement weather or a water main break can be daunting for utility companies. The Contact Center acts as an overflow call center for your utility customers during times of emergency or outages.'
      },
      {
        description: 'Our U.S.-based agents can also handle routine inquiries related to your service areas, pricing and what type of utilities you support. With our commitment to first-call resolution, there are few calls that will require a follow-up from your in-house team.'
      },
      {
        description: 'Not only do The Contact Center agents resolve your customers’ questions, complaints and issues, but we also act as an extension of your company, working to build relationships and shore up customer loyalty.'
      }
    ]
  }
]

export const specializedService = [
  {
    title: 'Talk With Customers on Their Terms',
    descriptions: [
      {
        description: 'Offering your customers the best level of service requires a custom approach to your contact center. The Contact Center develops a unique script that enables our agents to determine the urgency of the situation, as well as what steps need to be taken to resolve a customer’s issue.'
      },
      {
        description: 'Whether a customer is standing in several inches of water, or they simply need a service call in the next few days, our agents are equipped to dispatch an immediate response or coordinate scheduling a service call. In some cases, all that is required is a bit of troubleshooting, such as changing a dirty filter or flipping a circuit switch.'
      },
      {
        description: 'In addition, The Contact Center is a true multichannel contact center, giving your customers the convenience of contacting you in the way they prefer. From voice and text to email or chat, your customers enjoy contacting you in the format they find most suitable.'
      },
      {
        description: 'In an industry that supplies the most basic needs to its customers, you need an inbound contact center outsourcing solution that matches your level of excellence in service. Contact us at The Contact Center to learn why our customized scripts and unparalleled service will aid in your efforts to elevate your customers’ satisfaction.'
      },
      {
        description: 'With TCC’s inbound contact center services, your customers always reach a live person ready to listen to their questions or complaints and prepared to resolve the issue on the first call. Contact us today to learn more about how our services can help you stand out in your industry.'
      }
    ],
    link: '/',
    linkContent:'CONTACT US'
  }
]



