import React, { useEffect, useState } from 'react'
import Img from '../../../components/image/Img'
import Button from '../../../components/button/Button'
import Logo from '../../../../assets/images/home/brand.png'
import ArrowDown from '../../../../assets/images/svg/arrow-down.svg'
import IconX from '../../../../assets/images/home/simple-icons_x.svg'
import WhatsappFill from '../../../../assets/images/social/whatsapp-fill.svg'
import LinkedIn from '../../../../assets/images/social/linkedin.svg'
import Facebook from '../../../../assets/images/social/facebook.svg'
import Burger from '../../../../assets/images/svg/burger.svg'
import { Link, useLocation } from 'react-router-dom'
import './navbar.css'
import Underline from '../../underline/Underline'

export default function Navbar() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar dark-navbar pt-lg-3 navbar-expand-xl">
        <div className="container">
          <Link to='/' className='navbar-brand'>
            <h3 className='app__heading text-white'><span>T</span>he <span>C</span>ontact <span>C</span>enter</h3>
            {/* <Img src={Logo} width={'210px'} height={'50px'} alt={'Logo'} className={''} /> */}
          </Link>
          <div className={`navbar-collapse collapse ${expanded ? 'show' : ''} order-3 order-lg-3 order-xl-1`}>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-4">
              <li className="nav-item px-2 px-lg-0 dropdown">
                <span className="nav-link text-white d-flex gap-2 secondary-color fw-normal" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Outbound
                  <span className="position-relative -top-2">
                    <Img src={ArrowDown} width={'10px'} height={'10px'} alt={'Arrow Down'} className={''} />
                  </span>
                </span>
                <ul className="dropdown-menu beautiful-dropdown py-0">
                  <li><Link to='/outbound-marketing' className="dropdown-item text-whitee">Outbound Marketing</Link></li>
                  <Underline />
                  <li><Link to='/outbound-content-marketing-distribution' className="dropdown-item text-white">Outbound Content Marketing Distribution</Link></li>
                  <Underline />
                  <li><Link to='/outbound-lead-generation' className="dropdown-item text-white">Outbound Lead Generation</Link></li>
                  <Underline />
                  <li><Link to='/outbound-customer-development' className="dropdown-item text-white">Outbound Customer Development</Link></li>
                  <Underline />
                  <li><Link to='/outbound-b2b-list-for-business-growth' className="dropdown-item text-white">Outbound B2B List For Business</Link></li>
                  <Underline />
                  <li><Link to='/outbound-survey-and-market-search' className="dropdown-item">Outbound Survey And market Search</Link></li>
                  <Underline />
                  <li><Link to='/outbound-political-campaign' className="dropdown-item">Outbound Political Campaign</Link></li>
                  <Underline />
                  <li><Link to='/outbound-publisher-audience-development' className="dropdown-item">Outbound Publisher Audience Development</Link></li>
                </ul>
              </li>
              <li className="nav-item px-2 px-lg-0 dropdown">
                <span className="nav-link d-flex gap-2 secondary-color fw-normal" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Inbound
                  <span className="position-relative -top-2">
                    <Img src={ArrowDown} width={'10px'} height={'10px'} alt={'Arrow Down'} className={''} />
                  </span>
                </span>
                <ul className="dropdown-menu beautiful-dropdown py-0">
                  <li><Link to='/inbound-marketing' className="dropdown-item">Inbound Marketing</Link></li>
                  <Underline />
                  <li><Link to='/inbound-customer-services' className="dropdown-item">Inbound Customer Service</Link></li>
                  <Underline />
                  <li><Link to='/inbound-omnichannel' className="dropdown-item">Inbound Omni Channel</Link></li>
                </ul>
              </li>
              <li className="nav-item px-2 px-lg-0 dropdown">
                <span className="nav-link d-flex gap-2 secondary-color fw-normal" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Industries
                  <span className="position-relative -top-2">
                    <Img src={ArrowDown} width={'10px'} height={'10px'} alt={'Arrow Down'} className={''} />
                  </span>
                </span>
                <ul className="dropdown-menu beautiful-dropdown py-0">
                  <li><Link to='/industries-landing' className="dropdown-item">Industries Landing</Link></li>
                  <Underline />
                  <li><Link to='/industries-automotive' className="dropdown-item">Industries Automotive</Link></li>
                  <Underline />
                  <li><Link to='/industries-b2b' className="dropdown-item">Industries B2B</Link></li>
                  <Underline />
                  <li><Link to='/industries-contractors' className="dropdown-item">Industries Contractors</Link></li>
                  <Underline />
                  <li><Link to='/industries-ecommerce' className="dropdown-item">Industries Ecommerce</Link></li>
                  <Underline />
                  <li><Link to='/industries-education' className="dropdown-item">Industries Education</Link></li>
                  <Underline />
                  <li><Link to='/industries-financial' className="dropdown-item">Industries Financial</Link></li>
                  <Underline />
                  <li><Link to='/industries-franchise' className="dropdown-item">Industries Franchise</Link></li>
                  <Underline />
                  <li><Link to='/industries-government' className="dropdown-item">Industries Government</Link></li>
                  <Underline />
                  <li><Link to='/industries-healthcare' className="dropdown-item">Industries Healthcare</Link></li>
                  <Underline />
                  <li><Link to='/industries-hospitality' className="dropdown-item">Industries Hospitality</Link></li>
                  <Underline />
                  <li><Link to='/industries-insurance' className="dropdown-item">Industries Insurance</Link></li>
                  <Underline />
                  <li><Link to='/industries-legal' className="dropdown-item">Industries Legal</Link></li>
                  <Underline />
                  <li><Link to='/industries-noneprofit' className="dropdown-item">Industries Noneprofit</Link></li>
                  <Underline />
                  <li><Link to='/industries-political' className="dropdown-item">Industries Political</Link></li>
                  <Underline />
                  <li><Link to='/industries-retail' className="dropdown-item">Industries Retail</Link></li>
                  <Underline />
                  <li><Link to='/industries-small-business' className="dropdown-item">Industries Small Business</Link></li>
                  <Underline />
                  <li><Link to='/industries-publisher' className="dropdown-item">Industries Publisher</Link></li>
                  <Underline />
                  <li><Link to='/industries-telecom' className="dropdown-item">Industries Telecom</Link></li>
                  <Underline />
                  <li><Link to='/industries-utilities' className="dropdown-item">Industries Utilities</Link></li>
                </ul>
              </li>
              <li className="nav-item px-2 px-lg-0">
                <a href='http://form.thecontact.center/form/join_team'
                  target='_blank'
                  className='button-link-atag'
                >
                  <span className="nav-link d-flex gap-2 secondary-color fw-normal" role="button" aria-expanded="false">
                    Join The Team
                    <div data-tf-live="01J27GEWMHEV6RWVSPW6ZJ5ND4"></div><script src="//embed.typeform.com/next/embed.js"></script>
                  </span>
                </a>
              </li>
              <li className="nav-item px-2 px-lg-0 d-lg-none">
                <a
                  href='http://form.thecontact.center/form/consultation'
                  target='_blank'
                >
                  <Button text={'Consultation'} className={'btn primary-btn mt-0'} type={'button'} />
                </a>
              </li>
            </ul>
          </div>
          <div className='navbar-collapse order-2 order-lg-1 order-xl-2 navbar-collapse-auto d-lg-flex justify-content-start justify-content-lg-end justify-content-md-end me-3 me-md-0 me-lg-3 me-xl-0 d-none'>
            <li className="nav-item ms-auto list-unstyled mx-md-4 ms-lg-4 me-lg-0 btn-list">
              <a
                href='http://form.thecontact.center/form/consultation'
                target='_blank'
              >
                <Button text={'Consultation'} className={'btn primary-btn mt-0'} type={'button'} />
              </a>
            </li>
          </div>
          <button className="navbar-toggler order-1 order-md-2 order-lg-2 order-xl-2"
            type="button"
            data-bs-toggle="collapse"
            onClick={toggleNavbar}
            aria-expanded="false"
            aria-label="Toggle navigation">
            <Img src={Burger} width={'32px'} height={'20px'} alt={'Logo'} className={''} />
          </button>
        </div>
      </nav>
    </>
  )
}
