import React from 'react'
import { Link } from 'react-router-dom';
import ContentItemCard from '../content-item-card/ContentItemCard';
import './content-marketing.css'

export default function ContentMarketing({contentMarketing}) {
  return (
    <>
      <div className="content-marketing d-flex justify-content-center align-items-center h-100">
        {contentMarketing && contentMarketing.map((contentMarket, index) => (
          <div className="content-section position-relative pe-xl-3" key={index}>
            <ul className='d-flex gap-4 mb-0'>
              {contentMarket.meta.map((meta, index) => (
                <li key={index} className="list-item-marker me-2">
                  <Link to={meta.link} className='text-decoration-none primary-color font-poppins-medium left-4 default-sm-description'>{meta.data}</Link>
                </li>
              ))}
            </ul>
            <div className="content-marketing">
              <h1 className='font-poppins-bold custom-dark-color mb-2 mb-lg-1 default-extra-xl-title'>
                {contentMarket.title}
              </h1>
              <p className="default-sm-description">
                {contentMarket.description}
              </p>
            </div>
            <div className="row content-marketing-items-section pe-xl-3">
              {contentMarket.contentItems && contentMarket.contentItems.map((contentItem, index) => (
                <div className={`col-10 col-xl-${index === contentMarket.contentItems.length - 1 && index % 2 === 0 ? '6 m-lg-auto' : '6'}`} key={index}>
                  <ContentItemCard
                    key={index}
                    icon={contentItem.icon}
                    link={contentItem.link}
                    data={contentItem.data}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
