import Creating from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Helping from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE IN HOSPITALITY'
      },
    ],
    title: 'Hospitality Call Center Services',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality. Start offering the hospitality call center services of your dreams!',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Creating Excellent Guest Experiences',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "There’s no schedule to hospitality; it’s a never-ending dedication to meeting your customers’ expectations. Just as you fulfill a request, serve a meal or finish an event, there’s another one right behind it.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Top it all off with a ringing phone, a jammed email inbox or an unanswered text message, and you’ve got a high-stress work environment. But there’s a solution.",
      }
    ],
    image: Creating,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Helping You Create the Ideal Experience',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Whether you run a bed-and-breakfast, an event-planning business, a restaurant or an event venue or hotel, the constant pace can take a toll on your staff. And in order to maintain your place in your market, you have to uphold a certain level of service and friendliness.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'At TCC, our completely U.S.-based agents provide the helpful service you would offer if you were able to answer every call yourself. We act as an extension of your brand, with a friendly greeting, listening carefully to your customers’ requests. In addition, we are a multichannel inbound contact center, so whether your guests prefer email, text, or a phone call, they will receive unparalleled assistance.'
      },
    ],
    image: Helping,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Benefits You’ll Appreciate',
    subTitle: 'We provide the extra support you need, with the flexibility to scale contact center services up and down as needed to serve your guests.',
    descriptions: [
      {
        description: 'The travel, leisure, and hospitality industries are particularly sensitive to seasonal changes, with high volumes during the summer and holidays. We provide the extra support you need, with the flexibility to scale contact center services up and down as needed to serve your guests. Best of all, your guests enjoy an excellent experience as we assist them with booking services, cancellations, and travel assistance.'
      },
      {
        description: 'With phone calls handled quickly and efficiently by TCC, your employees will have more time to attend to the needs and requests of your in-person guests. When it’s your busy season, your guests will marvel at the efficiency and hospitality they receive throughout their experience.'
      },
      {
        description: 'To learn more about how The Contact Center can help you create the ideal experience for your guests, contact us for program pricing and details.'
      }
    ]
  }
]
