import CustomerService from '../../assets/images/inbound/image 49 (1).jpg';
import OmniChannel from '../../assets/images/inbound/image 50 (1).jpg';
import RedirectArrow from '../../assets/images/svg/redirect-arrow.svg';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const inboundServices = [
  {
    title: 'Lead Generation',
    description: 'Generating targeted leads and unlocking opportunities for your brand. Our goal is simple – Integrate with our client’s sales and marketing effort to become a new profit center. We do everything in our power to demonstrate the highest possible ROI.',
    url: CustomerService,
    icon: RedirectArrow,
    Rectangle: Rectangle
  },
  {
    title: 'Content Marketing Distribution',
    description: 'Telemarketing allows a company to distribute content to a segmented market, based on a prospect’s or client’s specific business needs, as well as follow up with their target audience to determine whether the content is being received.',
    url: OmniChannel,
    icon: RedirectArrow,
    Rectangle: Rectangle
  }
]