import Efficient from '../../assets/images/inbound-customer-services/growth_svgrepo.com.svg';
import Customer from '../../assets/images/inbound-customer-services/support_svgrepo.com.svg';
import Flexible from '../../assets/images/inbound-customer-services/simple-handshake_svgrepo.com.svg';
import Scalable from '../../assets/images/inbound-customer-services/expand_svgrepo.com.svg';
import Core from '../../assets/images/inbound-customer-services/focus-2_svgrepo.com.svg';
import Diamonds from '../../assets/images/inbound-customer-services/suit-diamonds_svgrepo.com.svg';
import Global from '../../assets/images/inbound-customer-services/global_svgrepo.com.svg';
import Technological from '../../assets/images/inbound-customer-services/three-squares_svgrepo.com.svg';

import Communication from '../../assets/images/inbound-customer-services/Frame.svg';
import Data from '../../assets/images/inbound-customer-services/security_svgrepo.com.svg';
import Quality from '../../assets/images/inbound-customer-services/quality-supervision_svgrepo.com.svg';
import TimeZon from '../../assets/images/inbound-customer-services/map-time_svgrepo.com.svg';
import CustomerDissatisfaction from '../../assets/images/inbound-customer-services/checkmark-circle_svgrepo.com.svg';
import Loss from '../../assets/images/inbound-customer-services/control-discipline-moderation_svgrepo.com.svg';


export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'A SEAMLESS EXPANSION OF YOUR INTERNAL TEAM'
      },
    ],
    title: 'Customer Service Outsourcing',
    description: 'The Contact Center  creates tailored teams of outstanding customer service experts available around the clock who are proficient using not only phone communications, but also e-mail, chat, text and digital and social media channels.',
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'Cost-Efficient Support',
        icon: Efficient,
      },
      {
        link: '/outbound-marketing',
        data: 'Loyal Customer Care',
        icon: Customer,
      },
      {
        link: '/outbound-marketing',
        data: 'Flexible Contracts',
        icon: Flexible,
      },
      {
        link: '/outbound-marketing',
        data: 'Scalable Operations',
        icon: Scalable,
      },
      {
        link: '/outbound-marketing',
        data: 'Core Focus',
        icon: Core,
      },
    ]
  },
]

export const weSpecialize = [
  {
    listItem: "The primary purpose of customer service outsourcing is to lower costs, streamline operations, improve efficiency, and enhance the overall customer experience and loyalty."
  },
  {
    listItem: "Outsourcing customer service can involve tasks such as phone support, email support, live chat assistance, technical support, and other related services. You should choose to outsource your customer service to leverage our specialized expertise, reduce your operational costs, increase customer satisfaction and loyalty, and divert all internal resources to focus on your core competencies."
  },
  {
    listItem: "When selecting a partner like The Contact Center , done effectively, customer service outsourcing will lead to increased customer satisfaction, improved response times, and enhanced brand reputation. It is crucial for your company to select reputable and reliable outsourcing partners to ensure that the quality of customer service remains consistent and aligns with the company’s values and standards."
  }
]

export const whyShouldIOutsourceMyCustomerService = [
  {
    title: 'Why should I outsource my customer service?',
    description: 'Outsourcing your customer service can provide numerous significant benefits for your business, including:',
    ourServices:[
      {
        title: 'Communication Barriers',
        description: 'Language and cultural differences can lead to misunderstandings between customers and outsourced agents, potentially affecting the quality of customer service and the overall customer experience.',
        url: Efficient,
        offSet: ''
      },
      {
        title: 'Data Security Concerns',
        description: 'Sharing sensitive customer data with a third-party outsourcing provider can pose security risks if proper data protection measures are not in place, potentially leading to data breaches and loss of customer trust.',
        url: Flexible,
        offSet: ''
      },
      {
        title: 'Quality Control',
        description: 'Ensuring consistent service quality and adherence to brand guidelines across different outsourced locations can be challenging, especially if the outsourcing partner has varying standards or practices.',
        url: Diamonds,
        offSet: ''
      },
      {
        title: 'Lack of Flexibility',
        description: 'Some outsourcing providers may have rigid contracts or limited flexibility, making it difficult to adapt quickly to changing business needs or fluctuating customer service demands.',
        url: Core,
        offSet: ''
      },
      {
        title: 'Customer Dissatisfaction',
        description: 'If the outsourcing partner fails to meet service level, it can lead to customer dissatisfaction, negative reviews, and a tarnished brand reputation, ultimately impacting customer retention and loyalty.',
        url: Customer,
        offSet: ''
      },
      {
        title: 'Time Zone Differences',
        description: 'Dealing with outsourcing partners in different time zones can result in delayed responses and longer time,  leading to customer frustration and dissatisfaction.',
        url: Global,
        offSet: ''
      },
      {
        title: 'Loss of Company Control',
        description: 'Handing over customer service to an external partner means relinquishing some control over the customer experience, which can be challenging for businesses in terms of customer values.',
        url: Technological,
        offSet: ''
      }
    ]
  }
]

export const isItCostEffectiveToOutSourceMyCustomerService = [
  {
    title: 'Is it cost effective to outsource my customer service?',
    description: 'Reducing customer service costs without compromising customer satisfaction requires a strategic approach that prioritizes efficiency and effectiveness. Here are some effective strategies to consider for lowering your customer service costs:',
    ourServices:[
      {
        title: 'Cost Savings',
        description: 'Ensure 24/7 customer support through our inbound outsourcing services—be there when customers reach out, with response via telephone, live chat, text, and email.',
        url: Communication,
        offSet: ''
      },
      {
        title: 'Specialized Proficiency',
        description: 'From lead generation to Content Marketing Distribution, we support clients with customer development, winback programs, market research, and outbound surveys.',
        url: Data,
        offSet: ''
      },
      {
        title: 'Flexibility',
        description: 'Qualifying leads since 1991, The Contact Center. has served companies like DELL, HP, Motorola, SAP, KnowB4, and Code 42 with expertise and precision.',
        url: Diamonds,
        offSet: ''
      },
      {
        title: 'Core Activity Focus',
        description: 'Success hinges on selecting optimal channels for content distribution. Our process ensures timely engagement from decision-makers and influencers.',
        url: Quality,
        offSet: ''
      },
      {
        title: '24/7 Assistance',
        description: 'From lead generation to Content Marketing Distribution, we support clients with customer development, winback programs, market research, and outbound surveys.',
        url: TimeZon,
        offSet: ''
      },
      {
        title: 'Global Reach',
        description: 'Qualifying leads since 1991, The Contact Center. has served companies like DELL, HP, Motorola, SAP, KnowB4, and Code 42 with expertise and precision.',
        url: CustomerDissatisfaction,
        offSet: ''
      },
      {
        title: 'Technological Advances',
        description: 'Success hinges on selecting optimal channels for content distribution. Our process ensures timely engagement from decision-makers and influencers.',
        url: Loss,
        offSet: ''
      }
    ]
  }
]
