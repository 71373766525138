import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, ActionableLeads } from '../../utils/outbound-customer-development-winback/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import Service from '../../common/components/service/Service'
import './outbound-customer-development-winback.css'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import { Link } from 'react-router-dom'
import Underline from '../../common/components/underline/Underline'
import SEO from '../../common/components/ui/seo/SEO'


export default function OutboundCustomerDevelopmentWinBackPage() {
  return (
    <>
      <SEO
        title='The Best Call Center Solution for Customer Development and Winback'
        description='Unlock New Profit Opportunities by Identifying New Sales Opportunities from Existing and Past Clients.'
        name='Why are so Many Companies Leaving Money on the Table?'
        type='Telemarketing' />
      <Underline className={'d-block d-sm-none mt-3'}/>
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color mb-lg-3 default-special-lg-title px-lg-5 mx-lg-5">
            <span className='primary-color'>Why </span>are so Many Companies Leaving Money on the Table?
          </h2>
          <div className="mx-lg-4">
            <p className="mb-4 default-description">You heard it before, it cost ten times less to secure a sale with an existing client compare to a new client. So why so many companies neglect to <span className='primary-color font-poppins-bold'> <Link to={'/'} className='primary-color'> produce high profit margin sales</Link></span> from existing and past clients?</p>
            <p className="mb-4 default-description">Maybe the marketing department is focused on targeting new audience, maybe the sales team neglect to service existing clients, or fear asking past clients to come back. Whatever the reason may be, you should set aside some of your sales and marketing resources forcing on easy sales from new and past clients.</p>
            <p className="mb-4 default-description">Companies come to rely so heavily on passive Digital marketing and <span className='primary-color font-poppins-bold'><Link to={'/'} className='primary-color'>sales engagements,</Link></span> that it is too easy to forget that most people still prefer to do business with people. People love to connect with people, not machines.</p>
            <p className="mb-4 default-description">Don’t make the same mistake, pick up the phone and call your clients, make them feel you care about them and in the process find out what else you can sell them. Reach out to past clients to find out why they left you and what it will take to bring them back.</p>
            <div className="pt-2 pt-lg-4">
              <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 mb-5 we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="primary-bg-light pt-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="font-poppins-bold custom-dark-color default-lg-title">
                Identify the <span className='primary-color'>customers </span> ready to buy now.
              </h2>
              <div className="py-4 text-center">
                <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
              </div>
              <p className='default-lg-description font-poppins-semi-bold mb-1'>We Provide Customer Service that Never Sleeps.</p>
              <p className='default-md-description primary-color'>Around the clock service built with your business in mind.</p>
            </div>
            <div className="col-12 py-3">
              <div className="row identify-the-customers-section">
                {ActionableLeads.map((ActionableLead, index) => (
                  <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${ActionableLead.offSet}`} key={index} >
                    <Service
                      title={ActionableLead.title}
                      description={ActionableLead.description}
                      url={ActionableLead.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
