export const connectionIsTheHear = [
  {
    title: 'connection is the heart of communication',
    descriptions: [
      {
        description: 'The telecom industry is a competitive one, with customers fully aware that another company is ready to receive their business. Offering a better way to communicate is an important way that your company can differentiate itself in the market.'
      },
      {
        description: 'Whether it’s satellite television, Internet service or cable companies, customers have learned that the norm is having to wait. Long hold times and automated voice responses cause customers to hang up in frustration. That’s why it’s a perfect opportunity for you to offer something different.'
      }
    ]
  }
]

export const jumpOnOpportunity = [
  {
    title: 'Jump on an Opportunity',
    descriptions: [
      {
        description: 'While the telecom industry is associated with high call volumes and long hold times, this is a prime opportunity for your enterprise to gain a competitive edge. From responding to calls to live chat assistance and answering emails, The Contact Center agents can handle new accounts, complaints, billing inquiries, technician dispatching and more.'
      },
      {
        description: 'Our agents provide help desk services, walking your customers through basic troubleshooting before scheduling a tech support appointment, reducing your costs and improving customer satisfaction.'
      }
    ]
  }
]

export const specializedService = [
  {
    title: 'Specialized, Customized Service',
    descriptions: [
      {
        description: 'There are a number of areas where our U.S.-based agents can help take the pressure off your agents while building up customer loyalty and satisfaction:'
      },
      {
        description: 'First-Call Resolution: 24/7 support means that your customers’ issues are resolved on the first call, to their complete satisfaction. We can handle billing inquiries, new account set-up, product information, technician dispatch and more.'
      },
      {
        description: 'Appointment Scheduling: When it’s a request for a new service or a technician call, our contact center can set up appointments for you. What’s more, we’re a multichannel provider, allowing your customers to contact us in their preferred format, from text and email to voice calls and chat.'
      },
      {
        description: 'Support for In-House Teams: Take the pressure off your sales and accounts receivable teams. Agents are trained in upselling and cross-selling as well as responding to billing inquiries, so that your sales and accounts receivable teams can focus on more complex issues.'
      },
      {
        description: 'With TCC’s inbound contact center services, your customers always reach a live person ready to listen to their questions or complaints and prepared to resolve the issue on the first call. Contact us today to learn more about how our services can help you stand out in your industry.'
      }
    ]
  }
]
