import Results from '../../assets/images/svg/results.svg';
import BoostSales from '../../assets/images/svg/boost-sales.svg';
import Settings from '../../assets/images/svg/settings.svg';
import Sales from '../../assets/images/svg/sales.svg';

import ContentMarketing from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import WeHelp from '../../assets/images/outbound-content-marketing-distribution/image 41.png';

import Lead from '../../assets/images/outbound-content-marketing-distribution/quality-leads/Group.svg';
import Nurturing from '../../assets/images/outbound-content-marketing-distribution/quality-leads/rise_svgrepo.com.svg';
import Database from '../../assets/images/outbound-content-marketing-distribution/quality-leads/clear_svgrepo.com.svg';
import TargetList from '../../assets/images/outbound-content-marketing-distribution/quality-leads/list-check_svgrepo.com.svg';
import Appointment from '../../assets/images/outbound-content-marketing-distribution/quality-leads/settings-1390_svgrepo.com.svg';
import Customer from '../../assets/images/outbound-content-marketing-distribution/quality-leads/customer-qudaoliebiao-line_svgrepo.com.svg';
import Event from '../../assets/images/outbound-content-marketing-distribution/quality-leads/calendar-check-fill_svgrepo.com.svg';
import Conduct from '../../assets/images/outbound-content-marketing-distribution/quality-leads/performance-graph_svgrepo.com.svg';
import Support from '../../assets/images/outbound-content-marketing-distribution/quality-leads/support_svgrepo.com.svg';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

import BM from '../../assets/images/companies/image 23.svg';
import Cisco from '../../assets/images/companies/image 24.svg';
import AWS from '../../assets/images/companies/image 25.svg';
import RedHat from '../../assets/images/companies/image 26.svg';
import Verizon from '../../assets/images/companies/image 27.svg';
import Nvidia from '../../assets/images/companies/image 28.svg';
import SalesForce from '../../assets/images/companies/image 29.svg';
import Microsoft from '../../assets/images/companies/image 30.svg';

export const contentMarketing = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'QUALITY LEADS'
      },
      {
        link: '/inbound-marketing',
        data: 'BETTER DISTRIBUTION'
      }
    ],
    title: 'Content Marketing Distribution',
    description: 'Generate Quality Leads with Better Content Marketing Distribution Strategies. Unlocking New Business opportunities for Our Clients Since 1991',
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'QUALITY LEADS',
        icon: Results,
      },
      {
        link: '/outbound-marketing',
        data: '20% Sales Boost',
        icon: BoostSales,
      },
      {
        link: '/outbound-marketing',
        data: '50% Marketing Savings',
        icon: Settings,
      },
      {
        link: '/outbound-marketing',
        data: '30% Sales Enhancement',
        icon: Sales,
      },
    ]
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Content Marketing is a waste without a proved distribution strategy.',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Current marketing strategies commonly include the generation of content in the form of blogs, white papers, videos, images, infographics, to name a few."
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The distribution of this content is conducted through carefully chosen channels. The form of distribution for content is a critical part of any marketing plan. Creating high-quality content without securing that it reaches the target audience will yield poor results.'
      }
    ],
    image: ContentMarketing,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'We help our clients reach the right contact with each and every channel.',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Selecting the best distribution channels plays a crucial role in successful execution of a marketing plan. One must understand not only who their best audience is, but also how to reach it or run the risk of a failing marketing plan.'
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The Contact Center  helps our clients reach the right contact with every campaign. Our services have greatly impacted successful content distribution. Our professional calling campaigns utilize the latest software and hardware that help keep costs low and effectiveness high.'
      },
    ],
    image: WeHelp,
    imageRectangle:Rectangle,
    changeDirection: true
  },
]

export const yourContentMarketing = [
  {
    link: '/outbound-marketing',
    data: 'Lead Gen & Qualification',
    icon: Lead,
  },
  {
    link: '/outbound-marketing',
    data: 'Nurturing & Enhancement',
    icon: Nurturing,
  },
  {
    link: '/outbound-marketing',
    data: 'Database Cleanup',
    icon: Database,
  },
  {
    link: '/outbound-marketing',
    data: 'Target List Development',
    icon: TargetList,
  },
  {
    link: '/outbound-marketing',
    data: 'Appointment Setting',
    icon: Appointment,
  },
  {
    link: '/outbound-marketing',
    data: 'Customer Development',
    icon: Customer,
  },
  {
    link: '/outbound-marketing',
    data: 'Manage live/virtual event invites.',
    icon: Event,
  },
  {
    link: '/outbound-marketing',
    data: 'Conduct marketing research and analysis',
    icon: Conduct,
  },
  {
    link: '/outbound-marketing',
    data: 'Email Follow-up & Support',
    icon: Support,
  },
]

export const companies = [
  {
    logo: BM
  },
  {
    logo: Cisco
  },
  {
    logo: AWS
  },
  {
    logo: RedHat
  },
  {
    logo: Verizon
  },
  {
    logo: Nvidia
  },
  {
    logo: SalesForce
  },
  {
    logo: Microsoft
  }
]
