import React from 'react'
import Img from '../image/Img'

export default function RectangleImage({imageRectangle, image}) {
  return (
    <>
      <div className="rectangle-image-section position-relative w-100 h-100 overflow-hidden d-flex align-items-center justify-content-center">
        <Img src={imageRectangle} width={''} height={''} alt={'Lead image'} className={'rectangle-image display-image-rectangle img-fluid border-rounded-20 w-100'} />
        <Img src={image} width={''} height={''} alt={'Lead image'} className={'display-image img-fluid border-rounded-20'} />
      </div>
    </>
  )
}
