import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies } from '../../utils/industries-small-business/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesSmallBusinessPage() {
  return (
    <>
      <SEO
        title='Small Business Call Center'
        description='The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='Small Business Call Center'
        type='Business Call Center' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color default-lg-title">
            Customized Services to
          </h2>
          <h2 className="font-poppins-bold custom-dark-color default-lg-title mb-3">
            Maximize Opportunities
          </h2>
          <div className="px-xl-5 mx-xl-4 pb-md-4">
            <p className="mb-4 default-lg-description font-poppins-semi-bold">You’ve grown your business from the ground up, and there’s a certain sound and feel to your interactions with your valued customers. At TCC, we act as an extension of your company, wording our conversations with customers just as you would through a customized plan.</p>
            <p className="mb-0 default-description">We also capture opportunities, using carefully crafted conversations to qualify leads and gain important details about budget, timeline, decision-making processes and more. Your sales team will receive only qualified leads that represent opportunities ready to convert.
              In addition, phone coverage won’t end when you walk out the door. Whether it’s covering extended hours, busy seasons of the year or a special promotion, outsourcing your contact center means capturing every potential lead and sale. We also offer a true multichannel solution, allowing your customers to choose their preferred contact format, including voice, text, email and chat.
              Missed calls mean missed opportunities, but it’s not a problem you’ll encounter when you partner with TCC. Contact us today to learn more about our customized plans for small businesses.
            </p>
          </div>
          <div className="pt-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
