import React from 'react'
import Img from '../../common/components/image/Img'
import Image from '.././../assets/images/login/wepik-export-20240220224745cfpv 1.png'
import VICI from '.././../assets/images/login/VICI.png'
import LoginFormFields from '../../common/components/login-form-fields/LoginFormFields'
import './login.css'

export default function LoginPage() {
  return (
    <>
    <div className="conatiner">
      <div className="row desktop-item">
        <div className="col-5 px-0">
          <Img src={Image} width={''} height={''} alt={'Form Image'} className={'image-fluid'} />
        </div>
        <div className="col-4 px-0 position-relative d-flex justify-content-center">
          <LoginFormFields largeScreen={true}/>
          <div className='position-absolute bottom-0'>
            <Img src={VICI} width={''} height={''} alt={'Form Image'} className={'image-fluid'} />
          </div>
        </div>
        <div className='col-8 offset-3 text-center my-3 custom-dark-color default-special-sm-title'>
          <p className='ms-5'>“Business is all about solving people’s problems — at a profit.”</p>
        </div>
      </div>
      <div className="row mobile-item py-5 my-5">
        <div className="col-12 px-5 col-lg-7 m-auto">
          <div className='pt-lg-5 mt-lg-5'></div>
          <LoginFormFields largeScreen={false}/>
          <div className='pb-lg-5 mb-lg-5'></div>
        </div>
      </div>
    </div>
    </>
  )
}
