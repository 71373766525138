import React from 'react'
import './service.css'
import Img from '../image/Img'

export default function Service({title, description, url}) {
  return (
    <>
    <div className="card service-card m-3 mx-md-0 mx-lg-2 my-4">
      <div className="card-body position-relative">
        {/* <Img src={url} width={'48px'} height={'48.62px'} alt={'Service Icon'} className={'position-absolute service-icon'} /> */}
        <h5 className="card-title custom-dark-color default-lg-description">{title}</h5>
        <p className="card-text default-special-md-description">{description}</p>
      </div>
    </div>
    </>
  )
}
