import Selling from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Surpassing from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR SMALL BUSINESSES'
      },
    ],
    title: 'Small Business Call Center',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Capturing Opportunities at a Cost-Effective Rate',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "When you operate a small business, hiring another employee is a big decision. You can focus on running your business with the peace of mind that comes with outsourcing your answering service. For a minimal investment, you can cover gaps in your in-house answering solution with an agent that can take care of anything from message-taking and appointment scheduling to handling FAQs.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "The Contact Center is entirely U.S.-based, offering your customers a consistent experience, no matter what time of day they call your business. From capturing leads to responding quickly to customer inquiries, we give you the freedom to focus on your business without losing any opportunities.",
      }
    ],
    image: Selling,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'So Many Hats, So Little Time',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'As a small business owner, you wear a lot of hats, and the decision to hire a dedicated customer service agent comes with significant cost associations. It’s not just the salary; it’s the benefits, the training, the risk that the talent hired may not match your expectations. But you know that allowing calls to go to voicemail isn’t a viable option. When placed in a voicemail inbox, most callers simply hang up. Fortunately, there’s a better solution. When you outsource your inbound contact center to TCC, you choose the hours you want to be covered and the services you would like our agents to provide. It is delivered at a rate that can’t be matched by an in-house contact center agent or receptionist.',
      }
    ],
    image: Surpassing,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

