import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import { TermsAndConditionsData, interpretationAndDefinitions, Definitions, AcknowledgmentData, OrderCancellationData, 
         YourOrderCancellationRightsData, AvailabilityErrorsData } from "../../utils/terms-and-conditions/Data";
import Company from '../../common/components/company/Company';
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction';


export default function TermsAndConditionsPage() {
  return (
    <>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          {TermsAndConditionsData.map((TermsAndCondition, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-title mb-3 mb-lg-5'>{TermsAndCondition.title}</h3>
              {TermsAndCondition.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {interpretationAndDefinitions.map((interpretation, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-privacy-title mb-3 mb-lg-5'>{interpretation.title}</h3>
              <h4 className='custom-dark-color font-poppins-bold default-title'>{interpretation.subTitle}</h4>
              <p className='default-sm-description'>{interpretation.description}</p>
            </div>
          ))}
          {Definitions.map((definition, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <h3 className='custom-dark-color font-poppins-bold default-privacy-title mb-3 mb-lg-5'>{definition.title}</h3>
              {definition.contentItems.map((contentItem, index) => (
                <div key={index}>
                  <p className='default-sm-description'>{contentItem.paragraph}</p>
                  <ul>
                    {contentItem.descriptions.map((description, index) => (
                      <li className='default-sm-description mb-2' key={index}><span className='custom-dark-color font-poppins-semi-bold'>{description.point} </span>{description.description}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          {AcknowledgmentData.map((uData, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h3 className='font-poppins-bold default-md-title'>{uData.title}</h3>
              </div>
              {uData.descriptions.map((description, index) => (
                  <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {OrderCancellationData.map((information, index) => (
            <div className='mb-3 mb-lg-5' key={index}>
              <div className='custom-dark-color'>
                <h3 className='font-poppins-bold default-md-title'>{information.title}</h3>
              </div>
              <p className='default-sm-description'>{information.paragraph}</p>
              <ul>
                {information.pointItems.map((pointItem, index) => (
                    <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
              {information.descriptions.map((description, index) => (
                <p className='default-sm-description mb-2' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
          {YourOrderCancellationRightsData.map((behavioralRemarking, index) => (
            <div className='mb-5' key={index}>
              <h3 className='font-poppins-bold default-title custom-dark-color mb-3'>{behavioralRemarking.title}</h3>
              {behavioralRemarking.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
              <ul>
                {behavioralRemarking.pointItems.map((pointItem, index) => (
                  <li className='default-sm-description mb-2' key={index}>{pointItem.point}</li>
                ))}
              </ul>
            </div>
          ))}
          {AvailabilityErrorsData.map((AvailabilityError, index) => (
            <div className='mb-3 mb-lg-4' key={index}>
              <h3 className='font-poppins-bold default-title mb-3'>{AvailabilityError.title}</h3>
              {AvailabilityError.descriptions.map((description, index) => (
                <p className='default-sm-description' key={index}>{description.description}</p>
              ))}
            </div>
          ))}
        </div>
        <div className="col-12 col-lg-4">
          <LetsGrowYourAudienceForm />
        </div>
        <div className="col-12 col-lg-8">
          <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 4 }} />
          <WeGuaranteeSatisfaction smallScreen={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
