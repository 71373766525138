import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies } from '../../utils/industries-political/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesPoliticalPage() {
  return (
    <>
      <SEO
        title='Political Call Center Services'
        description='The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='Political Call Center Services'
        type='Political Call Center' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color default-lg-title">
            The Support
          </h2>
          <h2 className="font-poppins-bold custom-dark-color default-lg-title mb-3">
            You Need
          </h2>
          <div className="px-xl-5">
            <div className="px-xl-5 mx-xl-4 pb-md-4">
              <p className="mb-4 default-lg-description font-poppins-semi-bold">Your organization depends on donations to reach your goals, and you must staff your contact center with individuals who best represent your values.</p>
              <p className="mb-4 default-description">At TCC, you have 24/7 coverage for those late-night donations, all delivered with a professional image that promotes the reputation of your nonprofit.</p>
              <p className="mb-4 default-description">We also understand that your bottom line is always a key consideration, and TCC’s affordable rates will make it easy for you to continue reaching for your goals. Funds are limited in your organization, so we make sure our rates help you funnel money to your core goals. When running a nonprofit organization, you can enjoy both low rates and excellent contact center services. Contact us at The Contact Center for details and pricing.</p>
            </div>
            <div className="pt-4">
              <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
