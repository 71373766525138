import Automotive from '../../assets/images/industries-landing/card.png'
import B2B from '../../assets/images/industries-landing/card (1).png'
import Contractors from '../../assets/images/industries-landing/card (2).png'
import Ecommerce from '../../assets/images/industries-landing/card (3).png'
import Education from '../../assets/images/industries-landing/card (4).png'
import Financial from '../../assets/images/industries-landing/card (5).png'
import Franchise from '../../assets/images/industries-landing/card (6).png'
import Government from '../../assets/images/industries-landing/card (7).png'
import Healthcare from '../../assets/images/industries-landing/card (8).png'
import Hospitality from '../../assets/images/industries-landing/card (9).png'
import Insurance from '../../assets/images/industries-landing/card (10).png'
import Legal from '../../assets/images/industries-landing/card (11).png'
import Nonprofit from '../../assets/images/industries-landing/card (12).png'
import Political from '../../assets/images/industries-landing/card (13).png'
import Publisher from '../../assets/images/industries-landing/card (14).png'
import Retail from '../../assets/images/industries-landing/card (15).png'
import SmallBusiness from '../../assets/images/industries-landing/card (16).png'
import Technology from '../../assets/images/industries-landing/card (18).png'
import Telecom from '../../assets/images/industries-landing/card (20).png'
import Utilities from '../../assets/images/industries-landing/card (21).png'

export const sectionContentList = [
  {
    title: 'The Contact Center acts as a natural extension to your company',
    listItems: [
      {
        listItem: "From adopting your brand voice to consistently communicating your values, outsourcing your contact center services allows you to seamlessly extend your resources.",
      },
      {
        listItem: "TCCInbound Contact Center Services does much more than simply answer calls placed to your business or follow a cold call script. Our services will support customer and prospect inquiries, requests and complaints as well as process orders coming in by e-mail, chat or social media. Our agents will handle all customer service inquiries, qualify and nurture leads, schedule appointments, take orders, perform data clean-up and enhancement services, offer upselling and cross-selling support and work to develop a trusted relationship between your company and the customer.",
      },
      {
        listItem: "There are several key benefits to investing in outsourced inbound contact center services and outsource customer service:",
      }
    ]
  },
];

export const bulletPoints = [
  {
    listItems: [
      {
        listItem: 'Contact center agents are trained to handle inquiries within the regulations governing industry compliance.'
      },
      {
        listItem: 'You choose the pricing model that works best for your needs.'
      },
      {
        listItem: 'It is ideal for businesses with high call volume or with omnichannel marketing strategies.'
      }
    ]
  }
]

export const CardListData = [
  {
    title: 'Automotive',
    description: 'Elevating Service in the Automotive Industry Automotive Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending',
    linkContent: 'Continue reading',
    link: '/',
    image: Automotive,
    alt: 'Automotive Logo'
  },
  {
    title: 'B2B',
    description: 'Elevating Service for B2B B2B Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: B2B,
    alt: 'B2B Logo'
  },
  {
    title: 'Contractors',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Contractors,
    alt: 'Contractors Logo'
  },
  {
    title: 'Ecommerce',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Ecommerce,
    alt: 'Ecommerce Logo'
  },
  {
    title: 'Education',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Education,
    alt: 'Education Logo'
  },
  {
    title: 'Financial',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Financial,
    alt: 'Financial Logo'
  },
  {
    title: 'Franchise',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Franchise,
    alt: 'Franchise Logo'
  },
  {
    title: 'Government',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Government,
    alt: 'Government Logo'
  },
  {
    title: 'Healthcare',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Healthcare,
    alt: 'Healthcare Logo'
  },
  {
    title: 'Hospitality',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Hospitality,
    alt: 'Hospitality Logo'
  },
  {
    title: 'Insurance',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Insurance,
    alt: 'Insurance Logo'
  },
  {
    title: 'Legal',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Legal,
    alt: 'Legal Logo'
  },
  {
    title: 'Nonprofit',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Nonprofit,
    alt: 'Nonprofit Logo'
  },
  {
    title: 'Political',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Political,
    alt: 'Political Logo'
  },
  {
    title: 'Publisher',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Publisher,
    alt: 'Publisher Logo'
  },
  {
    title: 'Retail',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Retail,
    alt: 'Retail Logo'
  },
  {
    title: 'Small Business',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: SmallBusiness,
    alt: 'SmallBusiness Logo'
  },
  {
    title: 'Technology',
    description: 'Copy is needed',
    linkContent: 'Continue reading',
    link: '/',
    image: Technology,
    alt: 'Technology Logo'
  },
  {
    title: 'Telecom',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Telecom,
    alt: 'Telecom Logo'
  },
  {
    title: 'Utilities',
    description: 'Elevating Service for Contractors Contractor Call Center Services The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions',
    linkContent: 'Continue reading',
    link: '/',
    image: Utilities,
    alt: 'Utilities Logo'
  }
]
