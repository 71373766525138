import Results from '../../assets/images/svg/results.svg';
import BoostSales from '../../assets/images/svg/boost-sales.svg';
import Settings from '../../assets/images/svg/settings.svg';
import Sales from '../../assets/images/svg/sales.svg';

import SalesStrategy from '../../assets/images/outbound-lead-generation/image 43.png';
import WeAreThere from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import TheContactCenter from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

import BANT from '../../assets/images/outbound-lead-generation/Group_1000000897.png';
import SQL from '../../assets/images/outbound-lead-generation/Group 1000000898.svg';
import MQL from '../../assets/images/outbound-lead-generation/Group 1000000899.svg';
import UsBasedSupport from '../../assets/images/outbound-customer-development-winback/flag.png';



export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'PROVIDING AROUND THE CLOCK CUSTOMER SERVICE'
      },
    ],
    title: 'The Best Call Center Solution for Customer Development and Winback',
    description: 'Unlock New Profit Opportunities by Identifying New Sales Opportunities from Existing and Past Clients.',
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'QUALITY LEADS',
        icon: Results,
      },
      {
        link: '/outbound-marketing',
        data: '20% Sales Boost',
        icon: BoostSales,
      },
      {
        link: '/outbound-marketing',
        data: '50% Marketing Savings',
        icon: Settings,
      },
      {
        link: '/outbound-marketing',
        data: '30% Sales Enhancement',
        icon: Sales,
      },
    ]
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Use Telemarketing for your customer retention efforts.',
    descriptions:[
      {
        linkContent: 'Use Telemarketing,',
        link: '/',
        linkType: false,
        description: "yes, I said it, do not leave telemarketing out of your customer retention efforts. No other method is more proactive, more personal, or as effective as telemarketing.However, it takes more than just a robust cold call script to improve a customer retention strategy; it takes an excellent ear to decide when it’s time to go off script and drill down into information that a customer really needs in order to further the relationship.",
      }
    ],
    image: SalesStrategy,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: '',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Being excellent communicators, telemarketers are also able to listen to the needs and concerns of customers. You need to understand the fears or hang-ups the client is having about a certain product or service. Despite new and updated methods of connecting to customers, the telemarketing experience brings the best results in customer retention and winback.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'It is less expensive to retain a customer than to acquire a new one, so retaining customer is important for your bottom line.'
      },
    ],
    image: WeAreThere,
    imageRectangle:Rectangle,
    changeDirection: true
  },
  {
    title: '',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The Contact Center  not only generates new business opportunities targeting new companies/people your sales staff could never reach, but we also provide exceptional customer development (retention) and Winback services.'
      },
      {
        linkContent: 'You sales reps',
        link: '/',
        linkType: true,
        description: 'are the most costly part of your sales process, let The Contact Center.’s representatives identify the customers who are ready to buy now, so your sales reps close new dela at a higher conversion rate and much faster. Contact us today to find out how we can help you grow your business faster and save you money in the process.'
      },
    ],
    image: TheContactCenter,
    imageRectangle:Rectangle,
    changeDirection: true
  },
]

export const ActionableLeads = [
  {
    title: "One Flat Rate​",
    description: 'We support simple, affordable call center solutions that any size company may afford. So, unwind. You are always under our 24/7 care. Other inbound call centers charge you more for after-hours, weekend, or holiday call answering, we don’t.',
    url: BANT,
    offSet: ''
  },
  {
    title: 'Call Handling Customized',
    description: "The way they handle calls varies depending on the type of business. We'll collaborate with you to assess your objectives and create a contact center script around them during the onboarding process.",
    url: SQL,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Virtual Receptionist Capabilities',
    description: 'Our live receptionists are skilled customer service experts that have a strong desire to assist others. The Contact Center  only employs enthusiastic representatives to transfer sales leads and skilled consultants to guide clients through troubleshooting processes.',
    url: MQL,
    offSet: ''
  },
  {
    title: 'US Based Support​',
    description: 'The Contact Center  is an established provider of US-based call centers. Our goal is to offer US-based representatives that are familiar with your customers and your industry.',
    url: UsBasedSupport,
    offSet: 'offset-lg-4'
  }
]
