import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import ContentInfo from '../../common/components/content-info/ContentInfo'
import { connectionIsTheHear, jumpOnOpportunity, specializedService } from '../../utils/industries-utilities/Data'
import Company from '../../common/components/company/Company'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesUtilitiesPage() {
  return (
    <>
      <SEO
        title='Be There for Your Customers'
        description='Your work doesn’t end at 5:00, because your customers use utilities at every hour of the day. Whether it’s an emergency with widespread outages, or a customer simply calling to decipher their invoice, you need to be able to provide quick, convenient information.'
        name='Utilities'
        type='Handling Emergencies' />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="title">
              <h5 className='custom-dark-color font-poppins-bold mb-3 mb-lg-5 default-title'>Utilities</h5>
            </div>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={connectionIsTheHear} />
            </section>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={jumpOnOpportunity} />
            </section>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={specializedService} />
            </section>
          </div>
          <div className="col-12 col-lg-4">
            <LetsGrowYourAudienceForm />
          </div>
          <div className="col-12 col-lg-8">
            <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 5 }} />
            <WeGuaranteeSatisfaction smallScreen={true}/>
          </div>
        </div>
      </div>
    </>
  )
}
