import BuildBetter from '../../assets/images/outbound-lead-generation/image 43.png';
import Providing from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

import Boost from '../../assets/images/industries-b2b/Group.svg';
import Provide from '../../assets/images/inbound-customer-services/simple-handshake_svgrepo.com.svg';
import Enjoy from '../../assets/images/outbound-lead-generation/Group 1000000899.svg';

import Simplified from '../../assets/images/outbound-b2b-list-for-business-growth/time.png';
import Customized from '../../assets/images/outbound-lead-generation/Group 1000000898.svg';
import Best from '../../assets/images/outbound-b2b-list-for-business-growth/solution.png';
import USBased from '../../assets/images/outbound-customer-development-winback/flag.png';


export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR COLLEGES & UNIVERSITIES'
      },
    ],
    title: 'Call Center Outsourcing Services Tailored to Your Needs',
    descriptions:[
      {
        description: 'From enrolment and registration to financial aid and placement, higher education institutions deal with several queries from students and their parents. Wouldn’t it be great if someone else could handle queries and complaints while you focus on what you do best – developing campus policies to improve the student experience?'
      },
      {
        description: 'That’s precisely what The Contact Center delivers. Our affordable multi-channel contact center solutions are available 24/7/365, meaning no more missed opportunities to enroll and retain students. Our US-based call center agents are available round-the-clock to address student concerns via calls, email, text, and chat in real time.'
      }
    ],
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'Boost student satisfaction by up to 20%',
        icon: Boost,
      },
      {
        link: '/outbound-marketing',
        data: 'Provide 24x7x365 handholding to students and parents.',
        icon: Provide,
      },
      {
        link: '/outbound-marketing',
        data: 'Enjoy cost savings of $30k per agent or more.',
        icon: Enjoy,
      }
    ]
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Customized Call Center Solutions for Colleges and Universities',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Students and parents need extensive support and guidance as they navigate the complex process of enrolling and paying for a higher education degree. It’s particularly important in today’s fast-evolving e-learning landscape. That, in turn, emphasizes the need for colleges and universities to invest in robust call center services.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Managing call center operations in-house is expensive, time-consuming, and not particularly scalable. And it can take a toll on student experience, making it difficult to retain them in the long run. In such a scenario, outsourcing your call center needs to a third-party provider is a more efficient alternative.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Educational institutions should not hand over student support to any random call center service provider. You need a trusted partner with extensive industry experience. At TCC, we bring all that and more to the table with our US-based contact centers and professionally trained agents. We provide both inbound and outbound call center solutions.",
      }
    ],
    image: BuildBetter,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'We Are an Extension of Your On-Campus Departments',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Be it admissions, financial aid, alumni office, or an ethics hotline, The Contact Center can serve as an extension to any department in your institution. Whether you want to outsource these functions completely to us or use our services to supplement your in-house student support operations, you have full control.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The best part?',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Our call center technology seamlessly integrates with your on-campus systems. That means we can work in conjunction with any department at your institution to schedule appointments, update student data, submit support tickets, and more, all minus any hiccups. And our US-based agents will be there for your students round-the-clock, even on holidays and weekends.',
      }
    ],
    image: Providing,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const IndustriesB2BTypes = [
  {
    title: "Simplified Pricing",
    description: 'Unlike other call center service providers, we provide a lucid fee structure with affordable, all-inclusive rates. You don’t have to pay extra to avail of after-hours services.',
    url: Simplified,
    offSet: ''
  },
  {
    title: 'Customized Call Handling',
    description: "We take the time to listen to you and understand your institution's needs, including call-handling workflows and system integrations. It allows us to create a contact center environment that meets your specifications.",
    url: Customized,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Best-in-Class Agents',
    description: 'Our call center agents receive extensive training to understand the nuances of addressing questions and/or complaints from students and parents. They’re driven by the desire to help you improve student satisfaction and grow your business.',
    url: Best,
    offSet: ''
  },
  {
    title: 'US Based Support​​',
    description: 'The Contact Center  is an established provider of US-based call centers. Our goal is to offer US-based representatives that are familiar with your customers and your industry.',
    url: USBased,
    offSet: 'offset-lg-4'
  }
]


export const sectionContent = [
  {
    title: 'Maximize Enrolment and Retention',
    subTitle: 'Did you know that when businesses aren’t available to address queries from prospects, they risk losing 70% of new customer opportunities?',
    descriptions: [
      {
        description: 'It holds true for colleges and universities too.'
      },
      {
        description: 'Every missed call from a prospective student or their parent is a missed business opportunity. Worse still, it can drive them to one of your competitors. In today’s higher education landscape, you can’t afford to lose potential students due to high call volumes, after hours or holiday calls.'
      },
      {
        description: 'The Contact Center helps you harness every new opportunity by ensuring prompt, real-time support. From capturing and addressing queries to finalizing enrolment and scheduling appointments, our agents handle it all. We strategically reconnect with students who haven’t completed their registration to minimize missed opportunities.'
      }
    ]
  }
]
