import React from 'react'
import './jumbotron.css'
import Button from '../button/Button'
import Img from '../image/Img'
import JumbotronImage from '../../../assets/images/home/jumbotron-image.png'

export default function Jumbotron() {
  return (
    <>
    <div className="container position-relative pb-4 my-3 my-xl-5 pb-xl-5">
      <section className='jumbotron-section position-relative'>
        <Img src={JumbotronImage} width={'100%'} height={'100%'} alt={'Logo'} className={'jumbotron-section-image position-relative'} />
        <div className="content-area text-white">
          <h1 className='font-poppins-bold'>MASTERS OF THE SALES!</h1>
          <div className="cta mt-3 mt-lg-4 d-flex justify-content-center">
            <a
              href='http://form.thecontact.center/form/consultation'
              target='_blank'
            >
              <Button text={'Consultation'} className={'btn primary-btn mt-0'} type={'button'} />
            </a>
          </div>
        </div>
      </section>
    </div>
    </>
  )
}
