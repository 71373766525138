import React from 'react'
import Img from '../image/Img';
import { Link } from 'react-router-dom';
import './content-item-card.css'

export default function ContentItemCard({icon, link, data}) {
  return (
    <>
    <div className="content-item-card px-2 px-xl-4 py-2 mb-3 my-md-3 d-flex align-items-center">
      <Link to={link} className='text-decoration-none secondary-color d-flex align-items-center h-100'>
        <Img src={icon} width={'20px'} height={'20px'} alt={'Link Icon'} className={'me-3'} />
        <p className="default-sm-description mb-0">
          {data}
        </p>
      </Link>
    </div>
    </>
  )
}
