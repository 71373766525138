import React from 'react'
import Img from '../image/Img';
import './how-we-do-it.css'
import RectangleImage from '../rectangle-image/RectangleImage';

export default function HowWeDoIt({title, description, imageUrl, imageRectangle, linksData, displayItem, changeDirection}) {
  return (
    <div className={`row align-items-top align-items-lg-center mb-md-5 ${changeDirection ? 'flex-row-reverse' : ''}`}>
      <div className={`col-12 col-lg-6 mb-2 px-md-0 order-2 order-lg-1`}>
        <div className="c-text-md-center">
          <h5 className="custom-dark-color default-md-title font-poppins-semi-bold">
            {title}</h5>
          <p className="default-md-description-extra mb-0 mb-md-3">{description}</p>
        </div>
        <div className='row px-2 px-sm-0'>
          {linksData.map((linkData, index) => (
            <div className={`${displayItem ? 'col-6 col-lg-4' : 'col-12 col-lg-6 c-text-md-center text-left' } mb-2`} key={index} >
              <a href={linkData.link} className='secondary-color default-special-description'>
                <span>{linkData.content}</span>
                <Img src={linkData.icon} width={'21px'} height={'11px'} alt={'Link logo'} className={'ms-2'} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={`col-12 col-lg-6 mb-2 order-1`}>
        <RectangleImage imageRectangle={imageRectangle} image={imageUrl} />
      </div>
    </div>
  )
}
