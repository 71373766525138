import Equipping from '../../assets/images/outbound-lead-generation/image 43.png';
import TheValue from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'OFFERING MORE TO GOVERNMENT AGENCIES'
      },
    ],
    title: 'Government Call Center',
    description: 'At TCC, we understand the challenge of wanting to provide excellent experiences but being limited by a budget. When you choose The Contact Center as Your government call center, you get the benefit of customized call center services for government agencies that allow every call to be answered by a completely U.S.-based contact center, while allowing your agency the time to work uninterrupted.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Equipping Governments to Serve',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "In recent years, businesses have been striving to improve the customer experience with agile technology and convenient features. Now governments can keep the pace, with contact center services that allow their inquiries, complaints and comments to be responded to in a timely and friendly manner.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "When the phone is ringing constantly, it can be challenging for government agencies to accomplish their goals and stick to a project timeline. While you want every call answered personally, it can be challenging to connect with every person on a tight budget.",
      }
    ],
    image: Equipping,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'The Value of Inbound Marketing for Government Agencies',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Inbound marketing contact center services help ensure that your calls are prioritized, whether you are at the federal, state or local level of government. Even with a limited budget, contact center services are a cost-efficient way to improve the experiences of your callers.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Private companies are investing in technology that allows them to offer superior customer experiences, but now the same trend is within reach for government agencies. First-call resolution will improve as you have trained agents working on a customized program that embraces your priorities and utilizes your policies for handling calls just the same way you would if they were in-house.'
      },
    ],
    image: TheValue,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Meeting Expectations',
    subTitle: 'No matter what area of the government your agency is located, TCChas the experience to represent your organization, from utilities and environmental protection to defense and housing.',
    descriptions: [
      {
        description: 'Today it is considered unthinkable to stay on hold for 30 minutes or more to talk to a representative, yet that’s the experience many governments are forced to offer. While technology makes it possible to avoid these kinds of wait times, it’s even more about the convenient experiences your callers receive.'
      },
      {
        description: 'The Contact Center offers a truly multichannel solution, with your residents able to contact you via a variety of formats, from email and chat to voice and text messaging. TCCalso offers customized scripts, so that if there are sensitive issues that your government is addressing, your residents will receive a consistent message.'
      }
    ]
  }
]
