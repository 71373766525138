import Providing from '../../assets/images/outbound-customer-development-winback/image 42.png';
import BuildBetter from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';
import growth from '../../assets/images/industries-b2b/Group.svg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR YOUR ECOMMERCE'
      },
    ],
    title: 'Ecommerce Call Center Services',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Build Better Customer Experiences',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "The customer experience has become a key differentiator for e-commerce brands. An immediate response is critical, and inbound contact center services can help ensure you are always available to meet the needs of your customers quickly and efficiently.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "An inbound contact center can provide assistance with anything from resolving customer issues to providing product information and order status updates, all with a consistent brand experience. Our team acts as an extension of your own, with customers receiving the same service levels that they have come to expect from your company.",
      }
    ],
    image: BuildBetter,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Providing Value and Excellence in Ecommerce',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'Despite the convenience of online buying, customers still appreciate the opportunity to talk with an agent about an online purchase, particularly when the order doesn’t go as planned. An inbound contact center can focus on handling these inquiries while your in-house team focuses on more complex goals around product development and innovation. Your plan for an inbound contact center includes accurate forecasting of call volume and customer needs, allowing you the precise level of support you require. When you receive a higher call volume or experience a sales boom, your inbound contact center services allow you to expand support without the expenses associated with hiring in-house staff.',
      }
    ],
    image: Providing,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const IndustriesB2BTypes = [
  {
    title: "Benefits of Choosing an Outsourced Inbound Contact Center",
    description: 'An inbound contact center service offers flexible solutions, with the ability to adjust according to your seasonal needs. And just as your customers enjoy shopping at any time of day, they can also enjoy the convenience of accessing customer support at any time of day.',
    url: growth,
    offSet: ''
  },
  {
    title: 'Careful Alignment with Your Brand',
    description: "A simple call answering service can help you manage a flood of calls with dedicated agents trained to represent your brand just as if they were an in-house representative. Appointment Scheduling: Removing routine tasks from your in-house contact center improves employee satisfaction, allowing your agents to focus on more complex customer requests.",
    url: growth,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Upselling and Promotional Messaging',
    description: 'Your inbound contact center services can be tailored to your organization’s goals. Agents can engage in revenue-growth activities, such as upselling and promotional messaging, that help your company reach the objectives that drive growth.',
    url: growth,
    offSet: ''
  },
  {
    title: 'Multichannel Services​',
    description: 'In addition to handling calls in our inbound contact center, The Contact Center offers multichannel services, fielding inquiries from your customers via phone, email, chat and text.',
    url: growth,
    offSet: 'offset-lg-4'
  }
]

export const sectionContent = [
  {
    title: 'Invest in Better Experiences',
    subTitle: 'Your customers know that if you aren’t available to answer their call, your competitor is ready.',
    descriptions: [
      {
        description: 'Building exceptional customer experiences has become a key distinguishing quality among competing companies. We help you close the gap on your customer experiences, ensuring your customers are always able to expect consistent, excellent encounters with your brand.'
      }
    ]
  }
]
