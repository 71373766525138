import React from 'react'
import Button from '../button/Button'

export default function PlanCard({title, planItems, totalAmount, type}) {
  return (
    <>
      <div className="card">
        <div className="content-header p-3">
          <h2 className="default-sm-title custom-dark-color w-lg-75">
            {title}
          </h2>
        </div>
        <div className="content-body p-3">
          <ul className='list-unstyled mb-5'>
            {planItems.map((planItem, index) => (
              <li className="plan-item" key={index}>
                <p className='default-special-md-description'>{planItem.name} <span className='primary-color font-poppins-semi-bold float-end'>{planItem.price}</span></p>
              </li>
            ))}
          </ul>
          <div className='text-center'>
            <Button text={totalAmount} className={'bg-transparent rounded-3 primary-border primary-color font-poppins-medium py-2 mt-0 w-100'} type={'button'} />
            <p className='my-2 default-sm-description'>{type}</p>
          </div>
        </div>
      </div>
    </>
  )
}
