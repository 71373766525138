import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import Publisher from '../../common/components/Publisher/Publisher'
import { ourServices, projectDetailsPointsList, whatHaveYouDone, theValueOfTheLeads, downloadCaseStudyData } from "../../utils/outbound-publisher-audience-development/Data";
import ImageCard from '../../common/components/image-card/ImageCard';
import SurveysAndMarket from '../../common/components/surveys-and-market/SurveysAndMarket';
import Img from '../../common/components/image/Img';
import PublisherImage from '../../assets/images/outbound-publisher-audience-development/Frame 1000001020.png'
import Company from '../../common/components/company/Company';
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction';
import DownloadCaseCard from '../../common/components/download-case-card/DownloadCaseCard';
import SEO from '../../common/components/ui/seo/SEO';

export default function OutboundPublisherAudienceDevelopmentPage() {
  return (
    <>
      <SEO
        title='Publisher'
        description='The Contact Center  has been servicing the publishing industry faithfully and exceptionally since 1991. Moving forward, our clients can expect to reduce the time, effort and resources needed to conduct their BPA audits.'
        name='The Contact Center  Received the iCompli partner program certification by BPA World Wide'
        type='Study' />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <Img src={PublisherImage} width={''} height={''} alt={'Company logo'} className={'image-fluid w-100'} />
            <Publisher />
          </div>
          <div className="col-12 col-lg-4">
            <LetsGrowYourAudienceForm />
          </div>
          <div className="col-12 col-lg-8">
            <div className="d-block d-md-none d-lg-none d-xl-none">
              <div className='company-slider'>
                <Company />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-12">
                <h2 className="mt-2 custom-dark-color font-poppins-bold default-extra-special-xl-title">Publisher Case Study</h2>
              </div>
              <p className='default-lg-description font-poppins-semi-bold custom-dark-color'>What have you done lately with your data to create new revenue?</p>
              <div className="info">
                {whatHaveYouDone.map((whatHaveYou, index) => (
                  <p className='default-md-description-extra' key={index}>{whatHaveYou.listItem}</p>
                ))}
              </div>
            </div>
            <div className="row pb-5 publish-card-section">
              <h5 className="default-lg-description custom-dark-color mt-3">How do you achieve the highest ROI?</h5>
              <p className='mb-4'>You need to make:</p>
              {ourServices.map((service, index) => (
                <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${service.offSet}`} key={index} >
                  <ImageCard
                    title={service.title}
                    description={service.description}
                    url={service.url}
                  />
                </div>
              ))}
              <p className='default-md-description-extra'><span className="font-poppins-semi-bold">It is very important to remember </span> that all of your clients have sales teams begging for quality leads they can sell to. Most of your clients do not have a proven system in place to find these prospects through a cold call script or targeting in marketing. Moreover, sales teams are struggling to reach their segmented market and prospects right now, and they will appreciate any help and support you can offer adding qualified actionable leads to their empty sales funnels.</p>
            </div>
            <div className="row">
              <div className="col-12">
                <h2 className="mt-2 custom-dark-color font-poppins-bold default-extra-special-xl-title">Project Details</h2>
              </div>
              <div className="info">
                <p className='default-md-description-extra'>
                  A publisher supporting his advertiser client was focused on generating qualified and actionable leads delivered daily to the sales force for an immediate follow-up.
                  The advertiser provides services needed for the ongoing care and maintenance of commercial buildings.
                  What did the advertiser consider a lead?
                </p>
              </div>
              <div className="mx-1">
                <div className="surveys-and-market-card card p-4">
                  <SurveysAndMarket pointsList={projectDetailsPointsList} allowEmptyBullet={false} />
                </div>
              </div>
              <div className='the-value-of-leads py-4'>
                <p className='default-lg-description font-poppins-semi-bold custom-dark-color'>The $ Value of the Leads</p>
                <div className="info">
                  {theValueOfTheLeads.map((theValueOfTheLead, index) => (
                    <p className='default-sm-description' key={index} >{theValueOfTheLead.listItem}</p>
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded p-4 border-rounded-10">
              <DownloadCaseCard data={downloadCaseStudyData}/>
            </div>
            <div className="py-5">
              <div className='d-none d-lg-block'>
                <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 4 }} />
              </div>
              <WeGuaranteeSatisfaction smallScreen={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
