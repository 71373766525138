import React from 'react'
import Button from '../button/Button'
import Img from '../image/Img'

export default function DownloadCaseCard({data}) {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-9 col-lg-9 mb-md-3">
          <div className="pe-lg-5">
            <h5 className='default-lg-description font-poppins-semi-bold custom-dark-color'>{data.title}</h5>
            <p className='default-md-description-extra'>{data.description}</p>
          </div>
          <div className='row d-none d-md-block'>
            <div className="col-12 col-lg-8 col-xl-6">
              <Button text={data.downloadContent} className={'btn primary-btn mt-0 w-100'} type={'button'} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex justify-content-lg-end justify-content-center">
          <Img src={data.image} width={'155px'} height={'200px'} alt={'Link logo'} className={'image-fluid w-100'} />
        </div>
        <div className='row d-block d-md-none mt-3 m-auto'>
          <div className="col-12 col-md-8 col-lg-6">
            <Button text={data.downloadContent} className={'btn primary-btn mt-0 w-100'} type={'button'} />
          </div>
        </div>
      </div>
    </>
  )
}
