import React from 'react'

export default function ContentInfo({contentInfo}) {
  return (
    <>
      {contentInfo.map((info, index) => (
        <div className='' key={index}>
          <h2 className='default-extra-special-xl-title custom-dark-color font-poppins-bold'>{info.title}</h2>
          {info.descriptions.map((description, index) => (
            <p key={index} className='default-sm-description'>{description.description}</p>
          ))}
        </div>
      ))}
    </>
  )
}
