import React from 'react'
import RectangleImage from '../rectangle-image/RectangleImage'
import Button from '../button/Button'
import { Link } from 'react-router-dom'

export default function DistributionStrategy({contentMarketingStrategies}) {
  return (
    <div className='my-lg-5'>
      {contentMarketingStrategies && contentMarketingStrategies.map((contentItem, index) => (
        <div className={`row mb-5 ${contentItem.changeDirection ? 'flex-row-reverse' : ''}`} key={index}>
          <div className={`col-12 col-lg-6`}>
            <RectangleImage imageRectangle={contentItem.imageRectangle} image={contentItem.image} />
          </div>
          <div className="col-12 col-lg-6 c-text-center">
            <div className="marketing-strategy h-100 d-flex mt-2">
              <div className="marketing-strategy-content-area m-auto">
                {
                  contentItem.title &&
                  <h5 className='default-title custom-dark-color mb-3 font-poppins-semi-bold'>{contentItem.title}</h5>
                }
                <div className="item-description mb-4">
                  {contentItem.descriptions.map((description, dIndex) => (
                    <p className='default-description ' key={dIndex}>
                      {
                        description.linkContent &&
                        <span className={`${ description.linkType ? 'font-poppins-bold' : ''}`}><Link to={'/'} className={`${description.linkType ? 'primary-color' : 'text-decoration-none'}`}>{description.linkContent}</Link> </span>
                      }
                      {description.description}
                    </p>
                  ))}
                </div>
                <Button text={'Lets Connect'} className={'btn primary-btn mt-2'} type={'button'} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
