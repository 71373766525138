import Discover from '../../assets/images/outbound-b2b-list-for-business-growth/discover.svg';
import Communicate from '../../assets/images/outbound-b2b-list-for-business-growth/communicate.svg';
import Convert from '../../assets/images/outbound-b2b-list-for-business-growth/convert.svg';

import Time from '../../assets/images/outbound-b2b-list-for-business-growth/time.png';
import SQL from '../../assets/images/outbound-lead-generation/Group 1000000898.svg';
import Solution from '../../assets/images/outbound-b2b-list-for-business-growth/solution.png';
import UsBasedSupport from '../../assets/images/outbound-customer-development-winback/flag.png';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'DISCOVER. COMMUNICATE. CONVERT.'
      },
    ],
    title: 'Enhance Your Sales Pipeline and Grow Your Business with Quality B2B Lists containing 100% Verified E-mails',
    description: 'Accelerate the process of identifying new business opportunities and enhance your company’s growth with greater efficiency and reduced expenses. By utilizing The Contact Center ’s B2B contact data, you can eliminate the need to invest months in compiling business lists.',
    contentItems: [
      {
        link: '/outbound-marketing',
        data: 'Discover: Target Connections',
        icon: Discover,
      },
      {
        link: '/outbound-marketing',
        data: 'Communicate: Seamless Engagement',
        icon: Communicate,
      },
      {
        link: '/outbound-marketing',
        data: 'Convert: Strategic Sales',
        icon: Convert,
      }
    ]
  },
]

export const ActionableLeads = [
  {
    title: "One Flat Rate​",
    description: 'We support simple, affordable call center solutions that any size company may afford. So, unwind. You are always under our 24/7 care. Other inbound call centers charge you more for after-hours, weekend, or holiday call answering, we don’t.',
    url: Time,
    offSet: ''
  },
  {
    title: 'Call Handling Customized',
    description: "The way they handle calls varies depending on the type of business. We'll collaborate with you to assess your objectives and create a contact center script around them during the onboarding process.",
    url: SQL,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Virtual Receptionist Capabilities',
    description: 'Our live receptionists are skilled customer service experts that have a strong desire to assist others. The Contact Center  only employs enthusiastic representatives to transfer sales leads and skilled consultants to guide clients through troubleshooting processes.',
    url: Solution,
    offSet: ''
  },
  {
    title: 'US Based Support​',
    description: 'The Contact Center  is an established provider of US-based call centers. Our goal is to offer US-based representatives that are familiar with your customers and your industry.',
    url: UsBasedSupport,
    offSet: 'offset-lg-4'
  }
]

export const accordionData = [
  {
    title: "Are your B2B lists authentic?",
    content: "Certainly! Our B2B lists are compiled from over 500 sources using reliable sources of business data, such as business directories, opt-in email addresses, business journals, and events, among many others. We ensure that all (100%) the contact details and data points we provide are thoroughly human verified, guaranteeing their accuracy and authenticity."
  },
  {
    title: "Are your lists up to date?",
    content: "Through their extensive experience and strong industry connections, The Contact Center  has positioned itself as a trusted partner in the B2B data and leads industry. Their portfolio of esteemed clients stands as a testament to their commitment to excellence and their dedication to helping businesses thrive."
  },
  {
    title: "Do you have lists with e-mails for every industry?",
    content: "Over the course of three decades, The Contact Center  has expanded its client base and formed partnerships with renowned companies including Microsoft, IBM, Nvidia, Cisco, Salesforce, AWS, and Verizon, among others. These collaborations highlight the company’s ability to adapt to changing market demands and cater to the needs of diverse businesses."
  },
  {
    title: "What are the designations of the contacts you provide?",
    content: "The Contact Center  has established itself as a reliable provider of B2B lists and qualified leads for numerous top companies in the industry. With a track record dating back to 1991, the company has consistently served esteemed clients such as Dell, Motorola, HP, SAP, and others."
  }
];
