import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies } from '../../utils/industries-retail/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesRetailPage() {
  return (
    <>
      <SEO
        title='Contact Center for Retail Industry'
        description='The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='Contact Center for Retail Industry'
        type='Retail Contact Center' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color default-lg-title">
            The Full Range of
          </h2>
          <h2 className="font-poppins-bold custom-dark-color default-lg-title mb-3">
            Services
          </h2>
          <div className="px-xl-5 mx-xl-4 pb-md-4">
            <p className="mb-4 default-lg-description font-poppins-semi-bold">From answering billing inquiries to order taking, return processing and handling frequently-asked questions about your products, we offer the fast, friendly service your customers expect from your company.</p>
            <p className="mb-0 default-description">We can also conduct surveys to help you understand how your customers experience your services, what they would like to see next in your product line and how likely they are to recommend your brand to a friend.</p>
            <p className="mb-0 default-description">Whether you are a small retail boutique or a large retailer with a big catalog, we customize a program to represent your company with professionalism. Our agents are trained to act as an extension of your company, offering service that keeps your customers returning again and again.</p>
            <p className="mb-4 default-description">We have pricing to fit any size of retail business and represent your brand with a customized script. Contact us to learn more about how The Contact Center supports your business growth with inbound contact center services.</p>
          </div>
          <div className="pt-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
