import Combining from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Trained from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'OFFERING THE BEST CALL CENTER FOR HEALTHCARE'
      },
    ],
    title: 'Medical & Healthcare Call Center',
    description: 'You’re striving to provide the best care for your patients, but your organization may need more support in order to effectively care for others. A fast and accurate response is more critical in the healthcare field than in any other industry, but the medical field is also vast, with varied needs. You need an inbound contact center service ready to create a customized program for your patients’ unique situations. Whether you run a small family practice and are looking for a way to manage calls that may otherwise be missed, or you’re an office manager for a large clinic that needs additional help during cold and flu season, The Contact Center can help you set up the call center for healthcare of your dreams.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Tailoring Services to Patient Preferences',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Beyond medical offices and clinics, TCCis equipped to support a wide range of healthcare services, including dental, home health, medical equipment and mental healthcare providers.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "TCC’s agents are completely U.S.-based, offering your patients an experience that is consistent with what they receive from your organization. In addition, TCCoffers a true multichannel inbound contact center, so that your patients are free to make contact in the way most convenient for them: voice call, text or email.",
      }
    ],
    image: Combining,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Improving Patient Experiences',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The Contact Center offers HIPAA-compliant inbound contact center services to organizations in the healthcare industry, from hospitals and clinics to providers, pharmaceutical companies and health insurance companies. Partnering with The Contact Center allows you to improve the patient experience with fast and accurate sharing of information while reducing your costs.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'From appointment scheduling to the handling of a drug recall hotline and qualifying leads for pharmaceutical reps, The Contact Center agents are specially trained to handle your contact center requirements.'
      },
    ],
    image: Trained,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Improve the overall efficiency of your organization',
    subTitle: 'From appointment scheduling to the handling of a drug recall hotline and qualifying leads for pharmaceutical reps, The Contact Center agents are specially trained to handle your medical call center requirements.',
    descriptions: [
      {
        description: 'Your patients will always be greeted by a friendly and professional agent, offering timely support to your patients and improving the overall efficiency of your organization.'
      },
      {
        description: 'Contact us at The Contact Center for more information about our inbound contact center service. You’re working hard to support your patients with an exceptional level of care, and we would like to, in turn, support you.'
      }
    ]
  }
]
