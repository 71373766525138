import React, { useCallback, useRef } from 'react'
import Img from '../image/Img'
import ProfileImage from '../../../assets/images/outbound-content-marketing-distribution/Ellipse 25.png'
import { testimonials } from '../../../utils/home/Data'
import './we-guarantee-satisfaction.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import Slider from '../Slider/Slider'
import topArrow from '.././../../assets/images/outbound-lead-generation/top.svg'
import bottomArrow from '.././../../assets/images/outbound-lead-generation/bottom.svg'
import SwiperCore from 'swiper';


export default function WeGuaranteeSatisfaction({smallScreen}) {
   const swiperRef = useRef(null);
  SwiperCore.use([Autoplay]); // don't need navigation anymore

  const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  return (
    <>
      <div className="py-3 py-md-5">
        <div className="row">
          <div className="col-12 order-2 order-md-1">
            <div className={`${smallScreen ? '' : 'px-lg-5'} text-center mx-2 mx-md-5 mx-lg-5 px-0`}>
              <p className="font-poppins-semi-bold mb-1 default-md-description">Testimonials</p>
              <h2 className={`font-poppins-bold custom-dark-color mb-3 mb-md-5 d-flex d-md-block ${smallScreen ? 'default-satisfaction-title' : 'default-special-xl-title'}`}> We Guarantee <span className='primary-color'> Satisfaction.</span></h2>
            </div>
          </div>
          <div className={`${smallScreen ? 'col-md-12 col-lg-12' : 'col-md-6 col-lg-6'} col-xl-6 col-12 order-1 order-md-2 we-guarantee-satisfaction position-relative py-md-3 py-lg-5`}>
            <div className="card rating-card border-0 pe-xl-4">
              <div className="card-body row">
                <div className="col-12">
                  <div className="d-flex align-content-center mb-4">
                    <div className="me-3">
                      <Img src={ProfileImage} width={''} height={''} alt={'Profile image'} className={''} />
                    </div>
                    <div className='m-auto'>
                      <h5 className="card-title custom-dark-color default-md-description font-poppins-semi-bold mb-2">ERIC MUENCH</h5>
                      <small className='d-block default-sm-description'>Director of Audience Development @ Trade Press Media Group, Inc.</small>
                    </div>
                  </div>
                  <p className="card-text mb-4 pt-4 position-relative profile-info default-sm-description">The Contact Center. is my exclusive telemarketing vendor. I appreciate the total package that they provide. From scripting and set up, to reporting and campaign management, right through to delivery of the final data files, everything has been 100% reliable, professional, and on time.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${smallScreen ? 'col-md-12 col-lg-12' : 'col-md-6 col-lg-6'} col-xl-6 col-12 order-3 order-md-3`}>
            <div className='rating-container w-100 h-100 position-relative ps-xl-5'>
              <Swiper
                ref={swiperRef}
                slidesPerView={'auto'}
                breakpoints={{
                  767: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    direction: 'horizontal'
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    direction:'vertical'
                  },
                }}
                autoHeight={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: '.prev',
                  nextEl: '.next',
                }}
                modules={[Autoplay]}
                loop={true}
                className={`${smallScreen ? 'small-screen-swipe-slider-vertical' : ''} swipe-slider-vertical h-100`}
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <Slider
                      key={index}
                      name={testimonial.name}
                      role={testimonial.role}
                      profileImage={testimonial.profileImage}
                      description={testimonial.description}
                    />
                  </SwiperSlide>
                ))}
                <div className='position-absolute action-container d-none d-md-flex'>
                  <div className="next" onClick={nextSlide}><Img src={topArrow} width={''} height={''} alt={'prev logo'} className={'me-2'} /></div>
                  <div className="prev" onClick={prevSlide}><Img src={bottomArrow} width={''} height={''} alt={'next logo'} className={'me-2'} /></div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
