import Value from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Cultivate from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'CALL CENTER SOLUTIONS FOR NONPROFITS'
      },
    ],
    title: 'Nonprofit Call Center',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Equipping You to Fulfill Your Mission',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "You’re committed to the mission of your organization, and The Contact Center is committed to equipping you to fulfill it. When it’s time to conduct your annual donation drive or you’re fielding high call volume from a telethon, your organization can count on our agents for a pleasant and easy donor experience.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Don’t leave your donors on hold. Your nonprofit organization needs the support of your donors, and The Contact Center equips your organization to receive their calls and minimize their wait.",
      }
    ],
    image: Value,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'We Represent YOU',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'When you partner with TCC, your donors interact with our entirely U.S.-based agents, trained to consistently represent your organization. And with our multichannel services, your donors contact you in their preferred format: call, text, email or chat to complete their gift. At TCC, we develop a customized script that allows you to maximize both the donor experience and the gifts your organization receives. We have perfected the art of encouraging generosity while ensuring that we never push too far. With our customized plans, our agents will conduct conversations just as your in-house employees would, acting as an extension of your organization. You can trust that your donors are receiving a consistent experience every time they have contact with our agents.',
      }
    ],
    image: Cultivate,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

