import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, IndustriesB2BTypes, sectionContent } from '../../utils/industries-b2b/Data'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ImageCard from '../../common/components/image-card/ImageCard'
import './industries-b2b.css'
import ContentSection from '../../common/components/content-section/ContentSection'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesB2BPage() {
  return (
    <>
      <SEO
        title='B2B Call Center Services'
        description='The Contact Center Marketing is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='ELEVATING SERVICE FOR B2B'
        type='B2B' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="pt-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12 py-3">
              <div className="row industries-b2b-section">
                {IndustriesB2BTypes.map((ActionableLead, index) => (
                  <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${ActionableLead.offSet}`} key={index} >
                    <ImageCard
                      title={ActionableLead.title}
                      description={ActionableLead.description}
                      url={ActionableLead.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <ContentSection sectionContent={sectionContent}/>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
