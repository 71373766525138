import React from 'react'
import { sectionContentList, bulletPoints, CardListData } from "../../utils/industries-landing/Data";
import SurveysAndMarket from '../../common/components/surveys-and-market/SurveysAndMarket';
import Img from '../../common/components/image/Img';
import { Link } from 'react-router-dom';
import './industries-landing.css'
import SEO from '../../common/components/ui/seo/SEO';

export default function IndustriesLandingPage() {
  return (
    <>
      <SEO
        title='The Contact Center acts as a natural extension to your company'
        description='From adopting your brand voice to consistently communicating your values, outsourcing your contact center services allows you to seamlessly extend your resources.'
        name='Omnichannel Services by The Contact Center. Contact Center Services'
        type='INDUSTRIES WE SERVE' />
      <div className="container">
      {/* INDUSTRIES WE SERVE start */}
        <div className="row py-5">
          <div className="col-12 col-lg-10 col-xl-8 m-auto">
            <div className='text-center'>
              <small className='primary-color font-poppins-medium'>INDUSTRIES WE SERVE</small>
            </div>
            {sectionContentList.map((contentList, index) => (
              <div className="section-content-list" key={index}>
                <h2 className='default-extra-lg-title font-poppins-bold text-center custom-dark-color mb-3'>
                  {contentList.title}
                </h2>
                {contentList.listItems.map((listItem, index) => (
                  <p className='default-description' key={index}>
                    {listItem.listItem}
                  </p>
                ))}
              </div>
            ))}
            <SurveysAndMarket pointsList={bulletPoints} allowEmptyBullet={false} />
          </div>
        </div>
      {/* INDUSTRIES WE SERVE end */}
      {/* Card List Data start */}
       <div className="row py-5">
        {CardListData.map((cardData, index) => (
          <div className="col-12 col-lg-6 col-xl-4 mb-4" key={index}>
            <Img src={cardData.image} width={''} height={''} alt={cardData.alt} className={'image-fluid w-100'} />
            <div className="card-content-area pt-4">
              <h5 className="fs-4 font-poppins-bold custom-dark-color mb-3">{cardData.title}</h5>
              <p className='mb-4'>{cardData.description}</p>
              <Link to={cardData.link} className="font-poppins-semi-bold primary-color">
                {cardData.linkContent}
              </Link>
            </div>
          </div>
        ))}
       </div>
      {/* Card List Data end */}
      </div>
    </>
  )
}
