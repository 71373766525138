import Cultivating from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Covering from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR CONTRACTORS'
      },
    ],
    title: 'Contractor Call Center Services',
    description: 'The Contact Center Marketing is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Managing Your Many Roles',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Your responsibilities as a contractor can span from providing estimates and checking on different job sites to collecting payments and managing inventory. You may also take on the roles of salesman, customer management and appointment scheduler. Even while you’re juggling all of these responsibilities, your phone is ringing. And each of those calls is a potential job, and even an opportunity to build a relationship that leads to a customer for life. You need a way to capture those valuable leads that help your business grow.",
      },
    ],
    image: Cultivating,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Time to Outsource?',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'As a contractor, you’re often forced to choose between following up on a lead or taking a call that will help move an existing project forward. When it seems as if there aren’t enough hours for all the communication you need to take care of, it’s time to outsource to TCC’s inbound contact center.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'From coverage during evenings and weekends to handle inquiries about potential projects, we allow you to focus on your projects while we keep your pipeline full with future opportunities. Our completely U.S.-based agents offer true multichannel marketing, giving your customers the ability to contact you via voice, text or email.'
      },
    ],
    image: Covering,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Customized for Contractors',
    subTitle: 'Your business is unique, and The Contact Center designs a program that fits the particular needs of your contracting business.',
    descriptions: [
      {
        description: 'From electricians that need the freedom to stay out on the job, to an HVAC business that needs to focus on billable work or a plumber that wants to build long-lasting relationships, The Contact Center builds a solution that focuses on your priorities. Even within your business, no two calls are the same.'
      },
      {
        description: 'That’s why The Contact Center designs custom scripts that handle a wide variety of calls, from appointment scheduling and common troubleshooting to onboarding a new customer. We offer seamless, friendly customer service that acts as an extension of your in-house team. You fill a lot of roles as a contractor, but you can take off the pressure by outsourcing your inbound contact center to TCC. Contact us to get started.'
      }
    ]
  }
]
