
import Communication from '../../assets/images/inbound-omnichannel/chat-round-line_svgrepo.com.svg';
import Email from '../../assets/images/inbound-omnichannel/envelope.svg';
import Social from '../../assets/images/inbound-omnichannel/social-media-campaign_svgrepo.com.svg';
import Direct from '../../assets/images/inbound-omnichannel/letter-communication-post_svgrepo.com.svg';

export const ourServices = [
  {
    title: 'Chat',
    description: 'Your customers want to engage immediately with a knowledgeable representative, and The Contact Center. Inbound Contact Center Services gives them immediate access to information about your products and services. Dedicated agents are trained to provide an experience consistent with your brand and values through chat communications. People like to communicate with people, not a machine.',
    url: Communication,
    offSet: ''
  },
  {
    title: 'E-mail',
    description: 'While not a real-time communication tool like chat, email can be a demanding task if a particularly engaging email campaign solicits a high number of responses. The Contact Center. Inbound Contact Center Services takes the pressure off your team by handling responses in a timely manner consistent with your brand values and message.',
    url: Email,
    offSet: ''
  },
  {
    title: 'Social Media',
    description: 'Comments on your social media posts are just what you want, but when a post goes viral or you’re struggling to keep up with conversations, social media management can become a burden. Allow The Contact Center. Inbound Contact Center agents to ease the load on your team for this marketing segment.',
    url: Social,
    offSet: ''
  },
  {
    title: 'Direct Mail',
    description: 'When responses to a piece of direct mail are flooding your website, or your call center, you need the right agents to follow up with every lead, inquiry, and request. The Contact Center. Inbound Contact Center addresses every lead and nurtures them, building a relationship between prospective clients and your company.',
    url: Direct,
    offSet: ''
  }
]

export const pointsList = [
  {
    listItem: "You have launched an omnichannel strategy, and you know it is just a matter of time before the calls start flooding your company. With The Contact Center. Inbound Contact Center Services, you can do more. That’s more communication with customers, more lead nurturing of prospects, more relationship-building and up-selling. You can do this all for less than what it may cost to handle in-house or with many other U.S. call center companies. When you partner with The Contact Center., you will save resources on phones, computers, software, office equipment and even the need to hire additional staff."
  },
  {
    listItem: "From chat to email and social media, The Contact Center. Inbound Contact Center Services allows you to professionally manage the flood of inquiries possible when your target market responds to an omnichannel strategy."
  },
]

