import Combining from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Trained from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE IN THE FINANCIAL INDUSTRY'
      },
    ],
    title: 'Financial Services Call Center',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Combining Precision and Service',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "In the finance industry, precision and accuracy are of the utmost importance. You can’t allow anything to be handled imperfectly or let details slip through the cracks. The Contact Center agents are trained to handle the intricacies of your business and industry.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "With unparalleled accuracy and training in compliance issues, your calls will be conducted with exacting, meticulous care.",
      }
    ],
    image: Combining,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Trained in Compliance and Accuracy',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'The push for access to fast and accurate information is a given to meet the needs of your clients. When your organization begins struggling to meet the requests of your clients and customers, you need a partner that can provide customer support solutions.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'That means not only delivering the right information to the right person but also protecting your brand and reputation through an exacting adherence to data privacy compliance regulations. Our specially-trained agents will protect your clients’ data, as well as your business continuity.'
      },
    ],
    image: Trained,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Customized Programs to Meet Your Clients’ Needs',
    subTitle: 'Whether you specialize in tax preparation, investment advising, finance or real estate investing, your clients have specific needs.',
    descriptions: [
      {
        description: 'Our U.S.-based agents are trained to handle the requests of your clients using customized scripts and a commitment to seamlessly work as an extension of your company. From resolving questions about an invoice to capturing a lead opportunity, our agents equip your organization to reduce costs while maximizing revenue.'
      },
      {
        description: 'In addition, as a multichannel inbound contact center, your clients are able to contact you in a variety of formats, including voice call, text and email, with all compliance regulations surrounding these communication formats intact. To learn more about the opportunities you have to expand and improve services to your clients while streamlining contact center operations, contact us at TCC.'
      }
    ]
  }
]
