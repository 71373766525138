import React from 'react'
import './contact.css'
import SEO from '../../common/components/ui/seo/SEO'
import ContactCenter from '../../assets/images/contact/image 2.png'
import {ReactComponent as GetInTouch} from '../../assets/images/svg/get-in-touch.svg'



export default function contact() {
  return (
    <>
      <SEO
        title='Call Center Outsourcing Services Tailored to Your Needs'
        description='From enrolment and registration to financial aid and placement, higher education institutions deal with several queries from students and their parents. Wouldn’t it be great if someone else could handle queries and complaints while you focus on what you do best – developing campus policies to improve the student experience?'
        name='Boost student satisfaction by up to 20%'
        type='Services' />
        <section className="contact-section-wrapper">
            <div className="contact-section d-flex flex-column align-items-center justify-content-center">
                <h1 className="title"><GetInTouch /></h1>
                <p className="subtitle text-center text-font">Reach out, and let's create a universe of possibilities together!</p>

                <div className="container contact-container bg-dark text-light rounded-4 p-4">
                    <div className="row g-4">
                        <div className="col-md-6 mt-5">
                        <h2 className='form-heading'>MASTERS OF THE SALES!</h2>
                        <p>Let's align our constellations! Reach out and let the magic of collaboration illuminate our skies.</p>
                        <form>
                            <div className="row mb-3">
                            <div className="col">
                                <input type="text" className="form-control bg-secondary text-light input-bg input-hug" placeholder="Last Name" required />
                            </div>
                            <div className="col">
                                <input type="text" className="form-control bg-secondary text-light input-bg input-hug" placeholder="First Name" required />
                            </div>
                            </div>
                            <div className="mb-3">
                            <input type="email" className="form-control bg-secondary text-light input-bg input-hug" placeholder="Email" required />
                            </div>
                            <div className="mb-3">
                            <input type="tel" className="form-control bg-secondary text-light input-bg input-hug" placeholder="Phone Number" required />
                            </div>
                            <div className="mb-3">
                            <textarea className="form-control bg-secondary text-light input-bg textarea-height" rows="3" placeholder="Message" required></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary w-100 submit-button">Send it to the moon 🚀</button>
                        </form>
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center quote_wrap">
                        <img src={ContactCenter} alt="Contact Center" className="img-fluid rounded-3 mb-3" />
                        <p className="quote">“Two lunar months revealed Earth's fragile beauty against vast silence, transforming my view of our place in the universe.”<br /><span>- Irinel Traista</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
