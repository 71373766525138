import React from 'react'
import './surveys-and-market.css'

export default function SurveysAndMarket({pointsList, allowEmptyBullet}) {
  return (
    <>
      {pointsList && pointsList.map((pList, index) => (
        <div className="points-section default-md-description-extra" key={index}>
          {
            pList.title && <p className={`${pList.titleWidth ? 'font-poppins-medium' : '' } `}>{pList.title}</p>
          }
          <ul>
            {pList.listItems && pList.listItems.map((lItem, index) => (
              <div key={index}>
                <li>{lItem.listItem}</li>
                { allowEmptyBullet && <li className="empty-bullet"></li> }
              </div>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}
