import React, { useState } from 'react';

export default function LoginFormFields({largeScreen}) {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      email: '',
      password: ''
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
          <div className="form-row row">
            <div className="form-group mb-4 col-12">
              <h4 className='custom-dark-color default-md-title font-poppins-semi-bold'>Welcome back</h4>
              <p className='default-description'>Please log in to access your account.</p>
            </div>
            <div className="form-group mb-4 col-12">
              <label className='mb-2 default-special-md-description custom-dark-color font-poppins-medium' htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control p-3"
                id="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mb-4 col-12">
              <label className='mb-2 default-special-md-description custom-dark-color font-poppins-medium' htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control p-3"
                id="password"
                placeholder="Enter password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group d-flex justify-content-end mb-4">
            <a href="/forgot-password" className={`forgot-password-link ${largeScreen ? 'light-blue-color' : 'primary-color'}`}>Forgot Password?</a>
          </div>
          <div className="form-group">
            <button type="submit" className={`text-white border-0 w-100 font-poppins-medium p-3 rounded ${largeScreen ? 'light-blue-bg' : "primary-bg"}`}>Login</button>
          </div>
        </form>
    </>
  )
}
