import React, { useState } from 'react';
import './lets-grow-your-audience-form.css';

export default function LetsGrowYourAudienceForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    businessEmail: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      fullName: '',
      businessEmail: '',
      message: ''
    });
  };

  return (
    <>
      <div className="lets-grow-your-audience-form rounded py-4 py-xl-5 px-3 px-xl-4 primary-bg-extra-light">
        <h5 className="mt-lg-3 default-special-sm-title custom-dark-color text-center font-poppins-semi-bold">
          Let's Grow Your Audience!
        </h5>
        <p className='text-center default-md-description-extra'>The Contact Center brings real value to your organization by delivering a set of inbound and outbound contact center services. Fill the form below to get started</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-4">
            <label className='mb-2 default-description' htmlFor="fullName">Full Name*</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Enter name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label className='mb-2 default-description' htmlFor="businessEmail">Business Email*</label>
            <input
              type="email"
              className="form-control"
              id="businessEmail"
              placeholder="Enter email"
              value={formData.businessEmail}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label className='mb-2 default-description' htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              rows="5"
              placeholder='Type here...'
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="text-white border-0 border-rounded-10 primary-bg w-100 font-poppins-medium p-3 rounded">Send</button>
          </div>
        </form>
      </div>
    </>
  );
}
