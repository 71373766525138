import OneBusiness from '../../assets/images/outbound-publisher-audience-development/up_svgrepo.com.svg'
import OneStrategic from '../../assets/images/outbound-publisher-audience-development/tactics-planning_svgrepo.com.svg'
import FocusOn from '../../assets/images/outbound-publisher-audience-development/choice_svgrepo.com.svg'
import PromiseImage from '../../assets/images/outbound-publisher-audience-development/stars_svgrepo.com.svg'
import HowAPublisher from '../../assets/images/outbound-publisher-audience-development/image 63.png'

export const ourServices = [
  {
    title: 'One business commitment',
    description: 'Decide to dramatically increase the number of leads you are generating and qualifying for your advertisers (be ready to get out of your comfort zone), and charge for all leads.',
    url: OneBusiness,
    offSet: ''
  },
  {
    title: 'One strategic decision',
    description: 'Make a commitment to integrate your sales, marketing, and audience development team for the purpose of these projects. Identify a leader to oversee that your instructions are being executed.',
    url: OneStrategic,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Focus on one integrated process ',
    description: 'Believe that every subscriber, member, or contact name in the database represents a new sales opportunity for one or more of your advertisers/clients.',
    url: FocusOn,
    offSet: ''
  },
  {
    title: 'Promise your clients',
    description: 'That you are dedicated to helping them overcome the COVID-19 economic downturn right now.',
    url: PromiseImage,
    offSet: 'offset-lg-4'
  }
]

export const pointsList = [
  {
    title: 'Our clients choose to work with us for the following reasons:',
    titleWidth: false,
    listItems: [
      {
        listItem: "Our Average cost per subscriber is often lower in comparison to other vendors.",
      },
      {
        listItem: "We are able to convert at a higher rate than other telemarketing vendors in the U.S. and abroad",
      },
      {
        listItem: "Our analysis and on time reporting is superior to other vendors.",
      },
      {
        listItem: "Our analysis and on time reporting is superior to other vendors.",
      },
      {
        listItem: "Our ability to deliver an overall greater value and greater savings is best realized when clients take advantage of the following combination.",
      },
      {
        listItem: "BVTM’s higher conversion rates of ReQual projects, produces the need for fewer New Names. Converting a higher % of New Names requires the purchase of fewer New Name records.",
      },
      {
        listItem: "The Contact Center ’s real-time analysis on new name lists enables purchase of smaller test lists before making a commitment to a larger, more expensive file. This leads to the purchase of ONLY the best performing lists. NOTE: We all know that good new name lists are difficult to come by.",
      },
      {
        listItem: "The Contact Center is helping our clients to identify new list sources, divided by marketing segment, saving them thousands of dollars a year.",
      },
      {
        listItem: "The Contact Center (BVTM) is offering our clients a volume and off-season discount. We believe that we can provide a significant savings over your current rate.",
      }
    ]
  },
];

export const projectDetailsPointsList = [
  {
    title: 'Leads were divided into FIVE groups and billed based on FOUR distinguished criteria:',
    titleWidth: false,
    listItems: [
      {
        listItem: "Once a lead was qualified, a follow-up e-mail was sent to thank the “lead” for their interest and strengthen the advertiser’s brand.",
      },
      {
        listItem: "Below is a summary report of the project describing how the ROI was calculated.",
      },
      {
        listItem: "450% ROI Process",
      },
      {
        listItem: "Lead Breakdown",
      },
      {
        listItem: "Total project cost = $6798.00",
      }
    ]
  },
];

export const whatHaveYouDone = [
  {
    listItem: "Below is a success story of one publisher who committed to help their advertisers grow their business and, in the process, generated over a 400% return on their investment (ROI). When you finish reading this report, you will likely reach a simple conclusion that you should implement a similar process to grow your business as well."
  },
  {
    listItem: "You can achieve similar results without spending tens of thousands of dollars or several months to build a new data system and infrastructure. With the right mindset, and the support of the sales and audience development teams, you can start immediately and expect excellent results. Publishers have to invest large amounts of money in audience development for quality data upkeep. With shrinking print revenues (down 50% in the last few years), the commitment to 100% one-year data accuracy is extremely challenging."
  },
  {
    listItem: "That shouldn’t stop you from making quality data a priority. We can show you a way to update and enhance your database, while at the same time providing your advertisers what they need most to grow their business (qualified leads)."
  },
  {
    listItem: "In today’s financial environment, can you afford to hesitate to implement a strategy to sell more and, in the process, help yourself to an amazing ROI?"
  },
]

export const theValueOfTheLeads = [
  {
    listItem: "The advertiser confirmed that these leads were better than any prior lead generation efforts or other lead source used."
  },
  {
    listItem: "The publisher’s audience development department confirmed that not only was the ReQual project subsidized by this project ($0 direct cost to their budget), but that they are expecting to finally be considered as a profit center for the organization and no longer have to carry the historical label of a cost center."
  },
  {
    listItem: "The publisher sales force, realizing the profitable opportunity, is dedicated more than ever to engage all advertisers and promote our lead generation process as part of their product offerings. They expect great demand, especially as companies continue to battle the challenging impact of COVID-19 and are looking for ways to fill up the sales team funnel with new business opportunities."
  },
  {
    listItem: "Contact us for additional details including production and audience breakdown analysis reports to help establish how you can experience the highest ROI on your projects."
  },
]

export const downloadCaseStudyData = {
  title: 'How A Publisher Can Earn a 450% Return on Their Investment',
  description: 'You can achieve similar results without spending tens of thousands of dollars or several months to build a new data system and infrastructure.',
  downloadContent: 'Download Case Study',
  image: HowAPublisher
}
