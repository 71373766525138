import Cultivating from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Covering from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

import growth from '../../assets/images/industries-b2b/Group.svg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR B2B'
      },
    ],
    title: 'B2B Call Center Services',
    description: 'The Contact Center Marketing is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Exceptional B2B Customer Experiences at an Affordable Cost',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "A positive experience with your company can lead to many referrals, but as a small business owner, it can be challenging to answer every call. But leave those calls to voicemail and you’re likely to lose opportunities.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Likewise, you may have a commitment to a high standard for customer service, but struggle to find the right talent or skills to provide the experience your customers want. It would be ideal if you had a dedicated employee to personally answer every call, but when you add in costs like benefits and training, it may be outside your budget.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "And you can’t entrust your company, your brand, even your reputation to just anyone; it has to be someone that understands your company and your industry.",
      }
    ],
    image: Cultivating,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Capturing Opportunities',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'To make the most of your B2B sales opportunities, the right inbound contact center will act as an extension of your brand, who understands your customers’ buying journeys and the various factors involved with a purchase.',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'You need representatives that can gather critical information: The various stakeholders involved in a purchasing decision. The potential budget and timeline of the purchase. The types of obstacles or questions that might need to be addressed. The types and formats of content that would be helpful for their decision-making process. You need a customized customer experience that feels like an extension of your in-house team.'
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'At TCC, our entirely U.S.-based contact center agents provide the friendly service consistent with your company. You need a partner that provides a customized program that allows agents to become your brand advocates as they offer exceptional service to your customers.'
      }
    ],
    image: Covering,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const IndustriesB2BTypes = [
  {
    title: "Our wide range of services",
    description: 'The Contact Center Marketing can help you close the gap between your commitment to customer service and the limitations of your budget. We create customized programs to fit your needs and provide brand consistency in how we represent your company.',
    url: growth,
    offSet: ''
  },
  {
    title: 'Basic Call Reception',
    description: "A simple call answering service can help you manage a flood of calls with dedicated agents trained to represent your brand just as if they were an in-house representative. Appointment Scheduling: Removing routine tasks from your in-house contact center improves employee satisfaction, allowing your agents to focus on more complex customer requests.",
    url: growth,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Order taking',
    description: 'Whether you’re running a promotion or entering a busy season, TCCB2B services can assist with taking customer orders, offering a consistent brand experience. Handling Complaints and Questions: Whether your customers need to pay an invoice, ask questions about an order status or register a complaint, The Contact Center handles the calls that tend to stack up in any contact center.',
    url: growth,
    offSet: ''
  },
  {
    title: 'Handling Complaints and Questions​',
    description: 'Whether your customers need to pay an invoice, ask questions about an order status or register a complaint, The Contact Center handles the calls that tend to stack up in any contact center.',
    url: growth,
    offSet: 'offset-lg-4'
  }
]

export const sectionContent = [
  {
    title: 'Benefits You Can Measure',
    subTitle: 'When you work with The Contact Center for your inbound contact center services, you can focus on growing your business, resting in the knowledge that your customers are receiving quality care.',
    descriptions: [
      {
        description: 'You will also get the benefit of appearing to be a bigger company, since your customers won’t know that TCCagents aren’t sitting right in your office. You’ll be able to compete more readily with larger B2B companies, because you will have agents working to capture leads and handle customer service requests for you.'
      },
      {
        description: 'The Contact Center offers a multichannel marketing program, allowing your customers to contact you in their preferred format, from email and text to voice call. The Contact Center offers customizable programs that are tailored to your needs. When you’re running a promotion or experiencing a seasonal surge in sales, The Contact Center is ready to accommodate your customers.'
      }
    ]
  }
]
