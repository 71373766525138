import React from 'react'
import Button from '../button/Button'

export default function ContentSection({sectionContent}) {
  return (
    <div>
      {sectionContent.map((content, index) => (
        <div className="container" key={index}>
          <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
            {content.title}
          </h2>
          <div className="px-xl-5">
            <p className="mb-4 default-lg-description font-poppins-semi-bold">{content.subTitle}</p>
            {content.descriptions.map((description, index) => (
              <p className="mb-4 default-description" key={index}>{description.description}</p>
            ))}
            <div className="pt-3">
              <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
