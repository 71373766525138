import React from 'react'
import { generatingTargetedLeads, contentMarketingStrategies, IndustriesB2BTypes, sectionContent } from '../../utils/industries-education/Data'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import './industries-education.css'
import ContentIndustries from '../../common/components/content-industries/ContentIndustries'
import Service from '../../common/components/service/Service'
import ContentSection from '../../common/components/content-section/ContentSection'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesEducationPage() {
  return (
    <>
      <SEO
        title='Call Center Outsourcing Services Tailored to Your Needs'
        description='From enrolment and registration to financial aid and placement, higher education institutions deal with several queries from students and their parents. Wouldn’t it be great if someone else could handle queries and complaints while you focus on what you do best – developing campus policies to improve the student experience?'
        name='Boost student satisfaction by up to 20%'
        type='Services' />
      <div className="container">
        <ContentIndustries contentIndustries={generatingTargetedLeads}/>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5">
        <ContentSection sectionContent={sectionContent} />
        <div className="container px-0 px-xl-5">
          <div className="row pt-md-5">
            <div className="col-12 py-3">
              <div className="row industries-education-section mx-1">
                {IndustriesB2BTypes.map((ActionableLead, index) => (
                  <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${ActionableLead.offSet}`} key={index} >
                    <Service
                      title={ActionableLead.title}
                      description={ActionableLead.description}
                      url={ActionableLead.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <div className='company-slider'>
        <Company />
      </div>
    </>
  )
}
