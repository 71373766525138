import React from 'react'
import Img from '../../image/Img'
import './footer.css'
import WhatsappFill from '../../../../assets/images/social/whatsapp-fill.svg'
import LinkedIn from '../../../../assets/images/social/linkedin.svg'
import IconX from '../../../../assets/images/home/simple-icons_x.svg'
import Facebook from '../../../../assets/images/social/facebook.svg'
import tel from '../../../../assets/images/footer/tel.svg'
import envelope from '../../../../assets/images/footer/envelope.svg'
import instagram from '../../../../assets/images/social/instagram.svg'
import location from '../../../../assets/images/footer/location.svg'
import { Link } from 'react-router-dom'
import Logo from '../../../../assets/images/home/logo-white.svg'
import contactCenterLogo from '../../../../assets/images/home/contact_center_logo.png'

export default function Footer() {
  return (
    <>
      <footer className="footer py-5 position-relative z-index">
        <div className="container">
          <div className="row text-white">
            <div className="col-sm-12 col-md-4 col-xl-4 text-center text-sm-start">
              <div className="the-contact-center">
                <h6 className="fs-5 fw-bold mb-3">
                  <Link to='/' className='navbar-brand'>
                    <Img className='favicon-icon' src={contactCenterLogo} width={'30px'} height={'20px'} alt={'Logo'}/>
                    <h3 className='app__heading'><span>T</span>he <span>C</span>ontact <span>C</span>enter</h3>
                  </Link>
                </h6>
                <p className='static-description-14 pb-0 pb-lg-5'>“The Contact.Center is an international organization that leverages outsourcing to provide a variety of customer service and support solutions for businesses. By outsourcing, companies can hire The Contact.Center as a third-party service provider to handle tasks such as customer support, technical assistance, sales, and other communication-based services.”</p>
                <div className="social">
                  <ul className="list-inline my-4 my-lg-5 d-flex justify-content-center">
                    <li className="list-inline-item d-none d-md-block">
                      <a target='_blank' href='https://www.instagram.com/thecontact.center/' className="px-1 px-md-2">
                        <Img src={instagram} width={'24px'} height={'24px'} alt={'Whatsapp'} className={''}/>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target='_blank' href='https://www.linkedin.com/company/105554567/admin/dashboard/' className="px-1 px-md-2">
                        <Img src={LinkedIn} width={'24px'} height={'24px'} alt={'Linkedin'} className={''}/>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target='_blank' href='https://x.com/thecontactcent' className="px-1 px-md-2">
                        <Img src={IconX} width={'24px'} height={'24px'} alt={'Simple Icon X'} className={''}/>
                      </a>
                    </li>
                    {/* <li className="list-inline-item d-none d-md-block">
                      <a className="px-1 px-md-2">
                        <Img src={Facebook} width={'24px'} height={'24px'} alt={'Facebook'} className={''}/>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-xl-5 text-center text-sm-start my-4 my-md-0">
              <div className="the-contact-center the-contact-center-auto">
              <h6 className="fs-5 fw-semi-bold mb-3">Services</h6>
                <ul className="list-unstyled footer-link">
                  <li className="mb-1"><a href="/outbound-marketing" className="text-decoration-none static-description-14 text-white fw-semibold">Outbound</a></li>
                  <li className="mb-1"><a href="/inbound-customer-services" className="text-decoration-none static-description-14 text-white fw-semibold">Inbound</a></li>
                  <li className="mb-1"><a href="/industries-automotive" className="text-decoration-none static-description-14 text-white fw-semibold">Industries</a></li>
                  <li className="mb-1"><a href="/industries-political" className="text-decoration-none static-description-14 text-white fw-semibold">Political</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-xl-3 text-center text-sm-start my-4 my-md-0">
              <div className="keep-in-touch">
                <h6 className="fs-5 fw-semi-bold mb-3">Keep In Touch</h6>
                <address className="mt-3 py-1 static-description-14 text-white fw-semibold d-block">
                  <Img src={location} width={'24px'} height={'24px'} alt={'Whatsapp'} className={'me-2'}/>
                  <span>fry road & u.s. 290 frontage road cypress TX 77433 | </span>
                  <span>Av.lope de vega #13, Plaza Progreso 506 Santo Domingo</span>
                </address>
                <a href="tel:+" className="pb-3 text-decoration-none static-description-14 text-white fw-semibold d-block"><Img src={tel} width={'24px'} height={'24px'} alt={'Whatsapp'} className={'me-2'}/> +1 346-544-6979</a>
                <a href="mailto:" className="pb-2 text-decoration-none static-description-14 text-white fw-semibold d-block"><Img src={envelope} width={'24px'} height={'24px'} alt={'Whatsapp'} className={'me-2'}/>  support@thecontact.center</a>
              </div>
            </div>
          </div>
          <div className="text-white mt-4 d-md-flex justify-content-between">
            <p className="mb-2 d-flex justify-content-center justify-content-md-left">The Contact.Center| Owned and Controlled by Codestra.co 2023 ©</p>
            <p className="mb-2 d-flex justify-content-center justify-content-md-left">The Contact.Center, All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  )
}
