import React from 'react'
import { Link } from 'react-router-dom';
import ContentItemCard from '../content-item-card/ContentItemCard';
import './content-industries.css'

export default function ContentIndustries({contentIndustries}) {
  return (
    <>
      <div className="content-industries-main-section">
        {contentIndustries && contentIndustries.map((contentIndustry, index) => (
          <div className="row py-2 py-lg-5 my-xl-5 content-industries position-relative" key={index}>
          <div className="col-12 col-lg-6">
            <ul className='d-flex gap-4 mb-0'>
              {contentIndustry.meta.map((meta, index) => (
                <li key={index} className="list-item-marker me-2">
                  <Link to={meta.link} className='text-decoration-none primary-color font-poppins-medium left-4 default-sm-description'>{meta.data}</Link>
                </li>
              ))}
            </ul>
            <div className="content-marketing-header">
              <h1 className='font-poppins-bold custom-dark-color mb-2 mb-lg-1 default-xl-title'>
                {contentIndustry.title}
              </h1>
            </div>
            <div className="row content-marketing-items-section">
              {contentIndustry.contentItems && contentIndustry.contentItems.map((contentItem, index) => (
                <div className='col-12 col-xl-11' key={index}>
                  <ContentItemCard
                    key={index}
                    icon={contentItem.icon}
                    link={contentItem.link}
                    data={contentItem.data}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mb-2">
            {contentIndustry.descriptions.map((description, index) => (
              <p className="default-sm-description" key={index}>
                {description.description}
              </p>
            ))}
          </div>
        </div>
        ))}
      </div>
    </>
  )
}
