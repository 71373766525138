export const pointsList = [
  {
    title: 'Surveys and market research…',
    titleWidth: true,
    listItems: [
      {
        listItem: "Help you keep your finger on the trends and pricing among your competitors.",
      },
      {
        listItem: "Equip you to create market segments for personalized messaging.",
      },
      {
        listItem: "Ensure your content is relevant and adding value for your audience.",
      },
      {
        listItem: "Allow you to determine customer satisfaction level, intent to purchase and customer loyalty.",
      },
      {
        listItem: "Our ability to deliver an overall greater value and greater savings is best realized when clients take advantage of the following combination.",
      },
      {
        listItem: "Collect data to identify industry trends and opportunities for your business.",
      },
      {
        listItem: "Give you valuable information to use in testimonials and content development.",
      }
    ]
  },
  
];
