import React from 'react'
import Img from '../../common/components/image/Img'
import WhatsappForward from '../../assets/images/home/whatsapp-forward.svg'
import '../outbound-marketing/outbound.css'
import { inboundServices } from '../../utils/inbound-marketing/Data'
import OutboundService from '../../common/components/outbound-service/OutboundService'
import Underline from '../../common/components/underline/Underline'
import SEO from '../../common/components/ui/seo/SEO'


export default function InboundMarketingPage() {
  return (
    <>
      <SEO
        title='Inbound Services'
        description='Inbound Solutions With You in Mind.'
        name='The Contact Center.'
        type='process' />
      <div className="container">
        <Underline className={''}/>
      </div>
      <div className='container'>
        {/* Inbound Services start */}
        <div className="py-0 py-md-5 outbound-service-main-section position-relative text-center overflow-hidden">
          <div className='py-4 py-lg-5'>
            <h1 className='font-poppins-bold custom-dark-color text-center mb-0 default-xl-title d-flex justify-content-center align-items-center'>
              <Img src={WhatsappForward} width={'48px'} height={'48px'} alt={'Whatsapp'} className={'me-3 outbound-service-whatsapp'}/>
              Inbound Services
            </h1>
          </div>
        </div>
        <Underline className={''}/>
        {/* Inbound Services end */}

        {/* Our Process start */}
        <div className="our-process-section py-3 py-lg-5">
          <div className="text-center px-xl-5 mx-xl-5">
            <p className="font-poppins-semi-bold mb-1 default-md-description">Our Process</p>
            <h2 className="font-poppins-bold custom-dark-color mb-lg-3 default-lg-title">Inbound Solutions With You in Mind<span className='primary-color'>.</span></h2>
            <div className="px-lg-5">
              <p className='default-sm-description px-lg-5 mx-lg-5'>Our inbound outsourcing services allows your business to be there for your customers 24/7/365. When customers reach out, they expect a response. Telephone call, live chat, text, and email support.</p>
            </div>
          </div>
          {inboundServices.map((obService, index) => (
              <OutboundService
                key={index}
                title={obService.title}
                description={obService.description}
                url={obService.url}
                icon={obService.icon}
                Rectangle={obService.Rectangle}
              />
          ))}
        </div>
        {/* Our Process end */}
      </div>
    </>
  )
}
