import Exceptional from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Capturing from '../../assets/images/outbound-lead-generation/image 43.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE FOR B2B'
      },
    ],
    title: 'B2B Call Center Services',
    description: 'The Contact Center Marketing is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Exceptional B2B Customer Experiences at an Affordable Cost',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "A positive experience with your company can lead to many referrals, but as a small business owner, it can be challenging to answer every call. But leave those calls to voicemail and you’re likely to lose opportunities. ",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Likewise, you may have a commitment to a high standard for customer service, but struggle to find the right talent or skills to provide the experience your customers want. It would be ideal if you had a dedicated employee to personally answer every call, but when you add in costs like benefits and training, it may be outside your budget. ",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "And you can’t entrust your company, your brand, even your reputation to just anyone; it has to be someone that understands your company and your industry. ",
      }
    ],
    image: Exceptional,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Capturing Opportunities',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'To make the most of your B2B sales opportunities, the right inbound contact center will act as an extension of your brand, who understands your customers’ buying journeys and the various factors involved with a purchase. ',
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'You need representatives that can gather critical information: The various stakeholders involved in a purchasing decision. The potential budget and timeline of the purchase. The types of obstacles or questions that might need to be addressed. The types and formats of content that would be helpful for their decision-making process. You need a customized customer experience that feels like an extension of your in-house team. '
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'At TCC, our entirely U.S.-based contact center agents provide the friendly service consistent with your company. You need a partner that provides a customized program that allows agents to become your brand advocates as they offer exceptional service to your customers. '
      }
    ],
    image: Capturing,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]


export const sectionContent = [
  {
    title: 'More time for you to get work done',
    subTitle: 'That means more test drives, more repairs finished, more items checked off your to-do list.',
    descriptions: [
      {
        description: 'All of these services mean more time for you to get work done. That means more test drives, more repairs finished, more items checked off your to-do list.'
      },
      {
        description: 'To learn more about the specific benefits you will experience when you outsource your inbound contact center services to The Contact Center Marketing, contact us today.'
      }
    ]
  }
]
