import React from 'react'
import { companies } from '../../../utils/outbound-content-marketing-distribution/Data'
import Img from '../image/Img'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';


export default function Company({slides}) {

  return (
    <div className='p-4'>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: slides ? slides.small : 4,
            spaceBetween: 30,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
          576: {
            slidesPerView: slides ? slides.small : 4,
            spaceBetween: 30,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
          1024: {
            slidesPerView: slides ? slides.medium : 6,
            spaceBetween: 30,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
          1025: {
            slidesPerView: slides ? slides.xLarge : 7,
            spaceBetween: 30,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
        }}        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay]}
        loop={true}
        className=""
      >
        {companies.map((company, index) => (
          <SwiperSlide key={index}>
            <div className='secondary-color d-flex justify-content-center align-items-center h-100'>
              <Img src={company.logo} width={''} height={''} alt={'Company logo'} className={'mx-3 company-logo'} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
