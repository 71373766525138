import Value from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Cultivate from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING RETAILER CUSTOMER SERVICE'
      },
    ],
    title: 'Contact Center for Retail Industry',
    description: 'The Contact Center is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Selling the Experience',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "No matter what you’re selling, your customers can always get it somewhere else. That’s why the exceptional customer experience must always be at the top of your priority list. In order to build loyalty and even create relationships where customers become your advocates, you need to offer a level of excellence that your competitors can’t match.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "The customer experience is more important than ever, and whether you operate a traditional store or are focusing on a future in online retail, loyal and repeat customers are critical to success. You need immediate, consistent and pleasant customer service to allow your brand to stand out in your market.",
      }
    ],
    image: Value,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Surpassing Service Expectations',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'When you partner with TCC, your donors interact with our entirely U.S.-based agents, trained to consistently represent your organization. And with our multichannel services, your donors contact you in their preferred format: call, text, email or chat to complete their gift. At TCC, we develop a customized script that allows you to maximize both the donor experience and the gifts your organization receives. We have perfected the art of encouraging generosity while ensuring that we never push too far. With our customized plans, our agents will conduct conversations just as your in-house employees would, acting as an extension of your organization. You can trust that your donors are receiving a consistent experience every time they have contact with our agents. Our entirely U.S.-based agents offer the same services as if the call were being handled by your in-house team. As a multichannel inbound contact center, we respond to customer inquiries in their preferred format: voice calls, messaging, text and email.',
      }
    ],
    image: Cultivate,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

