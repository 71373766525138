import Inbound from '../../assets/images/outbound-lead-generation/image 43.png';
import Insurance from '../../assets/images/outbound-customer-development-winback/image 42.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING SERVICE IN THE INSURANCE INDUSTRY'
      },
    ],
    title: 'Outsourced Insurance Call Centers',
    description: 'When it comes to insurance, the customer experience defines your brand. A customer that encounters a friendly, helpful agent that is focused on the first-call resolution of their questions or challenges is a customer that will tell their friends and family about your agency. From health to home and auto, insurance deals in the most important investments of your customer’s lives, and you need an inbound contact center with agents that put your customers at ease. Give them security and peace of mind with agents ready to listen carefully to their questions and assist them.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Inbound Contact Center Services',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "With our entirely U.S.-based, multichannel marketing support, The Contact Center is able to assist your customers in the ways they prefer to contact your company. From phone to text and email, our agents can field any format of communication to support your efforts.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Your callers will appreciate having access to agents 24 hours per day, seven days a week. Whether you deal in homeowners, life insurance, property, health insurance, or life insurance, our agents provide the coverage your company needs to prevent you from drowning in customer requests.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "The Contact Center allows your insurance company to navigate high call volumes with ease during peak times such as open enrollment season.",
      }
    ],
    image: Inbound,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Insurance for Your Customer Experience',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'By outsourcing your inbound contact center, you are able to fulfill the requests of your customers while processing claims and assisting new prospects. From homeowners to health and life insurance, liability, property or casualty insurance, we can assist with a range of calls, including accident reporting, membership hotlines, reception services, and claim support, allowing you to handle customer care requests and increase policyholder satisfaction. During open enrollment season, you can ease the pressure on your in-house agents, who are already handling an influx of calls, by outsourcing to our inbound contact center services. In addition, The Contact Center can provide assistance in proactively offering additional services to your customers, increasing your revenue-per-customer, and providing them with a streamlined insurance solution.',
      }
    ],
    image: Insurance,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Additional Essential Services to Support You',
    subTitle: 'Maximize the number of deals your sales team can close when you outsource your lead qualification tasks to TCC.',
    descriptions: [
      {
        description: 'People and companies reach out to request information which equates to leads. These leads can come from your web presence, print advertising, webinars, and shows. Leads can even be inactive customers and existing clients who are inquiring about additional products or coverage. We can reach these leads faster than your competitors (in real-time when needed).'
      },
      {
        description: 'We identify your best leads and return them warm so that your sales team needs only to follow up and close the deal. Even those 60- to 90-day-old leads should never be considered dead; there may be opportunities there that are now ready to decide, and The Contact Center is ready to nurture them for your team. Most importantly our agents are able to transfer these leads to your team in real-time to close the sale.'
      },
      {
        description: 'To learn more about the wide range of cost-saving benefits that The Contact Center can offer to your insurance agency, contact us today for pricing and program'
      }
    ]
  }
]
