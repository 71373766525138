
import React from 'react'
import Img from '../image/Img'

function ImageCard({title, description, url}) {
  return (
    <>
    <div className="card service-card mx-3 mx-md-0 mb-4">
      <div className="card-body text-center">
        <Img src={url} width={'48px'} height={'48.62px'} alt={'Service Icon'} className={'service-icon mb-2'} />
        <h5 className="card-title secondary-color default-md-description">{title}</h5>
        <p className="card-text default-sm-description">{description}</p>
      </div>
    </div>
    </>
  );
}

export default ImageCard;
