import React from 'react'
import Img from '../image/Img'
import './outbound-service.css'
import RectangleImage from '../rectangle-image/RectangleImage'

export default function OutboundService({title, description, url, icon, Rectangle}) {
  return (
    <>
      <div className="row outbound-service-item py-1 py-lg-5">
        <div className="col-12 col-lg-4">
          <RectangleImage imageRectangle={Rectangle} image={url} />
        </div>
        <div className="col-12 col-lg-8">
          <div className="d-lg-flex justify-content-center align-items-center h-100 outbound-service-item-content c-text-center">
            <div className='px-3'>
              <h5 className='default-special-md-title custom-dark-color font-poppins-semi-bold my-2 mt-3 mt-lg-0'>{title}</h5>
              <p className='default-sm-description'>{description}</p>
            </div>
            <Img src={icon} width={''} height={''} alt={'Link logo'} className={'image-fluid me-3 linked-icon'} />
          </div>
        </div>
      </div>
    </>
  )
}
