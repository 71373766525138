import Value from '../../assets/images/outbound-content-marketing-distribution/image 40.png';
import Cultivate from '../../assets/images/outbound-content-marketing-distribution/image 41.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';

export const generatingTargetedLeads = [
  {
    meta:[
      {
      link: '/inbound-marketing',
      data: 'ELEVATING LEGAL CALL CENTER SERVICES'
      },
    ],
    title: '24/7 Legal Call Center for Attorneys and Law Firms',
    description: 'Our legal intake services are ideal for firms from the small law office with limited staff to the large corporation receiving hundreds of calls, chats and website form submissions related to their injury and lawsuit cases. The Contact Center can support the needs of your legal practice with agents trained to professionally handle calls in a fast-paced industry. Your clients often need counsel quickly, particularly if they have been hurt or mistreated, without the added difficulty of being stuck on hold or forced to leave a message.',
    contentItems: []
  },
]

export const contentMarketingStrategies = [
  {
    title: 'Value Delivered to Your Firm',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Our U.S.-based agents are trained to deliver the appropriate messaging to your clients according to protocols with agents adhering to the highest standards for privacy regulations and ensuring critical information is passed on to your firm.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "Agents can also handle appointment scheduling to ensure clients’ needs are met in a timely manner. A potential client in a challenging legal situation likely wants counsel quickly, and even being forced to stay on hold or leave a message on a voicemail system can cause them to hang up and find someone that is immediately available to take their call.",
      },
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: "An inbound contact center ensures that your firm doesn’t lose opportunities due to the difficulty of staffing enough representatives to take every call. You can secure the services of an inbound contact center for those busy times at a fraction of the expense of hiring a set of representatives to field calls.",
      }
    ],
    image: Value,
    imageRectangle: Rectangle,
    changeDirection: false,
  },
  {
    title: 'Cultivate a Reputation for Excellence',
    descriptions:[
      {
        linkContent: '',
        link: '/',
        linkType: false,
        description: 'In addition to handling calls, The Contact Center operates as a true multichannel solution, equipped to respond to inquiries via phone, email, text, chat and more. When you specialize in a service like legal expertise, you build a reputation for experience, skill and proficiency, like any law firm hoping to succeed. What may set you apart in your field is a commitment to effective communication. An inbound contact center allows you to efficiently stay in communication with clients, with agents trained in not only your privacy policies and specific business practices but also in your approach. Agents use customized scripts based on your preferences and business values so that they always represent your firm faithfully.',
      }
    ],
    image: Cultivate,
    imageRectangle:Rectangle,
    changeDirection: true
  }
]

export const sectionContent = [
  {
    title: 'Ready to Take Advantage of These Benefits?',
    subTitle: 'Your firm is working hard to bring peace of mind to clients, and The Contact Center is here to bring peace of mind to you and your employees.',
    descriptions: [
      {
        description: 'By outsourcing your inbound contact center needs, you can ensure that clients are receiving timely communications, and you’re never losing an opportunity due to a period of high call volume.'
      }
    ]
  }
]
