import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import SurveysAndMarket from '../../common/components/surveys-and-market/SurveysAndMarket'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import { pointsList } from "../../utils/outbound-survey-and-market-research/Data";
import Company from '../../common/components/company/Company'
import SEO from '../../common/components/ui/seo/SEO'


export default function OutboundSurveyAndMarketResearchPage () {
  return (
    <>
      <SEO
        title='Surveys And Market Research'
        description='Take the guesswork out of your marketing campaign to deliver a better return on investment and grow your business.'
        name='Surveys and market research'
        type='Testimonials' />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 default-sm-description">
            <div className="surveys-and-market-research mt-5">
              <h2 className='custom-dark-color font-poppins-bold mb-0 default-extra-special-xl-title'>Surveys And Market Research</h2>
            </div>
            <p className='default-lg-description font-poppins-semi-bold'>Call Center Surveys and Market Research</p>
            <div className="more-info py-3">
              <p className='mb-3'>Take the guesswork out of your marketing campaign to deliver a better return on investment and grow your business.</p>
              <p className='mb-3'>Far superior to relying on a gut instinct or anecdotal evidence, market research equips you to better understand your audience and their specific needs.</p>
              <p className='mb-3'>The Contact Center  offers market research that reaches out directly to your audience to understand their needs, opinions and wants through a personal (human to human) survey or questionnaire.</p>
              <p className='mb-3'>We select the best team to conduct the survey/research based on your needs. We follow your scripts or can write them for you.</p>
            </div>
            <div className="surveys-and-market-card card p-4">
              <SurveysAndMarket pointsList={pointsList} allowEmptyBullet={false} />
            </div>
            <div className="more-info py-4">
              <p className='mb-3'>The Contact Center  conducts surveys and market research by phone, which often is a preferable format to email, social media or other options. Your company is provided the data ready for compilation and analysis for business application.</p>
              <p className='mb-3'>From political to non-profit, B2B and other types of businesses, The Contact Center  provides valuable data. Your survey can be simple, with only a few questions, or it can be a more complex data-gathering campaign, with interviews lasting up to 30 minutes.</p>
              <p className='mb-3'>From determining who belongs in your target market to testing the performance of a campaign, surveys and market research helps you keep the focus on your audience and understanding them better.</p>
            </div>
            <div className="survey-reveals-section">
              <h5 className='default-lg-description font-poppins-semi-bold'>Survey Reveals Missed Sales Opportunities Through White Paper Distribution</h5>
              <div className="more-info py-2">
                <p className='mb-3'>The Contact Center. survey results indicate that white papers are the preferred method for receiving content, but many marketers are failing to capitalize on this opportunity.</p>
                <p className='mb-3'>White papers are the most popular method, with 37 percent of respondents reporting that they would rather receive a white paper than other types of content formats, but only 11 percent receive their content through white papers. Learn more about why you may be missing important opportunities in B2B marketing when you fail to prioritize white papers for content distribution.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <LetsGrowYourAudienceForm />
          </div>
          <div className="col-12 col-lg-8">
            <div className='d-block d-xl-none'>
              <Company slides={{ small: 4, medium: 4, large: 4 }} />
            </div>
            <WeGuaranteeSatisfaction smallScreen={true} />
          </div>
        </div>
      </div>
    </>
  )
}
