import { useState } from 'react';
import './accordion.css'

const Accordion = ({accordionData}) => {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? -1 : index);
  };

  return (
    <div className="accordion" id="answerAccordion">
      {accordionData.map((item, index) => (
        <div className={`accordion-item bg-transparent${activeAccordion !== index ? '' : ' active'}`} key={index}>

          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className={`accordion-button${activeAccordion === index ? '' : ' collapsed'} font-poppins-medium secondary-color bg-transparent`}
              type="button"
              onClick={() => toggleAccordion(index)}
              aria-expanded={activeAccordion === index ? 'true' : 'false'}
              aria-controls={`collapse${index}`}
            >
              {item.title}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse${activeAccordion === index ? ' show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-bs-parent="#answerAccordion"
          >
            <div className="accordion-body text-start">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
