import React from 'react'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import ContentInfo from '../../common/components/content-info/ContentInfo'
import { connectionIsTheHear, jumpOnOpportunity, specializedService } from '../../utils/industries-telecom/Data'
import Company from '../../common/components/company/Company'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesTelecomPage() {
  return (
    <>
      <SEO
        title='connection is the heart of communication'
        description='The telecom industry is a competitive one, with customers fully aware that another company is ready to receive their business. Offering a better way to communicate is an important way that your company can differentiate itself in the market.'
        name='Telecom'
        type='communication' />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="title">
              <h5 className='custom-dark-color font-poppins-bold mb-3 mb-lg-5 default-title'>Telecom</h5>
            </div>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={connectionIsTheHear} />
            </section>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={jumpOnOpportunity} />
            </section>
            <section className='mb-3 mb-lg-5'>
              <ContentInfo contentInfo={specializedService} />
            </section>
          </div>
          <div className="col-12 col-lg-4">
            <LetsGrowYourAudienceForm />
          </div>
          <div className="col-12 col-lg-8">
            <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 5 }} />
            <WeGuaranteeSatisfaction smallScreen={true}/>
          </div>
        </div>
      </div>
    </>
  )
}
